import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  OrderBillingStatus,
  useUpdateStandardOrderMutation,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import useBillingReviewStore from '../../../../invoices/billing-review-store';
import useBillingReviewActions from '../../../../invoices/hooks/use-billing-review-actions';
import { OnSubmitParams } from '../types';
import { deepCompareOrderChanges } from '../utils';
import { OrderFormValues } from './types';
import { useSaveSnapshot } from './use-save-snapshot';
import { executeOrderUpdateFn, updateOrder } from './utils';

const useSaveOrderBrm = () => {
  const { useAllCaps } = useMe();
  const [updateOrderMutation, { loading }] = useUpdateStandardOrderMutation();
  const { saveSnapshot } = useSaveSnapshot();
  const getValues = useFormContext<OrderFormValues>()?.getValues;
  const [addOrderUuidToSave, removeOrderUuidToSave, getInitialFormData] =
    useBillingReviewStore(
      (state) => [
        state.addOrderUuidToSave,
        state.removeOrderUuidToSave,
        state.getInitialFormData,
      ],
      shallow,
    );
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const { fetchOrderCacheFirst } = useBillingReviewActions();

  // Returns true if order is successfully saved or if save is not needed. Else returns false.
  const saveOrder = async ({
    forceRefresh = false,
    newBillingStatus,
    additionalUpdateFns = [],
  }: {
    forceRefresh?: boolean;
    newBillingStatus?: OrderBillingStatus;
  } & OnSubmitParams) => {
    if (!isNil(getValues)) {
      const orderValues = getValues();
      const initialFormData = getInitialFormData();
      const orderUuid = orderValues.uuid;
      try {
        if (isNil(orderUuid)) {
          throw new Error('Order UUID is missing');
        }
        addOrderUuidToSave(orderUuid);

        // Only update order if changes have been made
        const noChangesMade =
          !forceRefresh &&
          !isNil(initialFormData) &&
          deepCompareOrderChanges(initialFormData, orderValues);
        if (!noChangesMade) {
          // TODO: add error handling here
          const { res, errorMessage } = await updateOrder({
            updateOrderMutation,
            orderValues,
            saveSnapshot,
            newBillingStatus,
            useAllCaps,
          });

          if (!isNil(errorMessage)) {
            setErrorMessage(errorMessage);
            setShowErrorMessage(true);
            return false;
          }
          if (!isNil(res)) {
            for (const { fn, vars } of additionalUpdateFns) {
              await executeOrderUpdateFn({ fn, vars });
            }
            // update the billing review store.
            await fetchOrderCacheFirst({
              orderUuid,
              bypassCache: true,
            });
          }
        }

        removeOrderUuidToSave(orderUuid);
        return true;
      } catch (e) {
        console.error('Error saving order:', e);
        setErrorMessage(`Failed to save order ${orderValues?.name}`);
        setShowErrorMessage(true);
        return false;
      }
    }
    return true;
  };

  return { saveOrder, loading };
};

export default useSaveOrderBrm;

import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { useAccessorialsByBillingContactQuery } from '../../../../../generated/graphql';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { OrderFormFieldValues } from '../forms/types';
import { useOrderFormContact } from './use-order-form-contact';

const useOrderFormAccessorials = () => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const { contactUuid } = useOrderFormContact();
  const inboundTerminalUuid = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.terminalUuid`,
  });
  const outboundTerminalUuid = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.terminalUuid`,
  });

  const {
    data: accessorialsData,
    refetch,
    loading,
  } = useAccessorialsByBillingContactQuery(
    !isNil(contactUuid)
      ? {
          variables: {
            billingPartyContactUuid: contactUuid,
            terminalUuids: filterNotNil([
              inboundTerminalUuid,
              outboundTerminalUuid,
            ]),
          },
        }
      : { skip: true },
  );

  return {
    accessorials: accessorialsData?.accessorialsByBillingContact,
    refetch,
    loading,
  };
};

export { useOrderFormAccessorials };

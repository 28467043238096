import React, { Dispatch, SetStateAction } from 'react';
import { StopMethod } from '../../../forms/stop-type';
import { OnSubmitParams } from '../../../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../../constants';
import Stop from './stop';

const Stops = ({
  isEditMode,
  onSubmit,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: {
  onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  isEditMode: boolean;
  setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
}) => {
  return (
    <>
      <Stop
        idx={INBOUND_STOP_IDX}
        onSubmit={onSubmit}
        stopMethod={StopMethod.Inbound}
        isEditMode={isEditMode}
        setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
        setCannotCompleteOrderModalMessage={setCannotCompleteOrderModalMessage}
      />
      <Stop
        idx={OUTBOUND_STOP_IDX}
        onSubmit={onSubmit}
        stopMethod={StopMethod.Outbound}
        isEditMode={isEditMode}
        setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
        setCannotCompleteOrderModalMessage={setCannotCompleteOrderModalMessage}
      />
    </>
  );
};

export default React.memo(Stops);

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { ORDER_PAGE_MARK_AS_COMPLETE_MODAL_BUTTON_TEST_ID } from '../../../../constants';
import { getMarkAsTestIds } from '../../../../utils';
import { StopType } from '../../../domains/orders/components/order-form/forms/stop-type';
import { useUpdateAndRefetchOrder } from '../../../domains/orders/components/order-form/hooks/use-update-and-refetch-order';
import { OnSubmitParams } from '../../../domains/orders/components/order-form/types';
import {
  PickupOrDelivery,
  StopStatus,
  useCompleteStopBulkMutation,
  useMarkOrderAsCompleteForceMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import {
  MarkOrderAsReadyToInvoiceFormValues,
  useMarkOrderAsReadyToInvoiceForm,
} from '../../form/stops/use-mark-order-as-ready-to-invoice-form';
import useMe from '../../react-hooks/use-me';
import { DatePickerInput } from '../forms/date-picker-input';

const StyledDatePicker = styled(DatePicker)`
  '.rmdp-container.completed-at-date-picker-container' {
    width: 100%;
  }
`;

type MarkOrderAsReadyToInvoiceDialogProps = {
  handleClose: () => void;
  open: boolean;
  stops: {
    uuid: string;
    routeDate: Date | undefined;
    pickupOrDelivery: PickupOrDelivery | undefined;
    addressName: string | undefined;
    status: StopStatus;
    completedAt: Date | undefined;
    stopType?: StopType;
  }[];
  orderUuid: string;
  /** If null, we are not in form context so we don't perform the save before these updates */
  onSave: ((params: OnSubmitParams) => Promise<boolean>) | null;
  markAsRefused?: boolean;
};

export const MarkOrderAsReadyToInvoiceDialog = ({
  handleClose,
  open,
  stops,
  orderUuid,
  onSave,
  markAsRefused,
}: MarkOrderAsReadyToInvoiceDialogProps) => {
  const theme = useTheme();
  const { companyConfiguration } = useMe();
  const requirePODPhotoAndName = companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfiguration?.requirePODPhotoAndNameForPickups ??
    requirePODPhotoAndName;
  const [markOrderAsCompleteForce] = useMarkOrderAsCompleteForceMutation();
  const [completeStopBulk] = useCompleteStopBulkMutation();
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const requirePODPhotoAndNameOverall =
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true ||
      stops.every((stop) => stop.stopType === StopType.Delivery));

  const {
    form: {
      control,
      reset,
      formState: { errors, isSubmitting },
      handleSubmit,
      watch,
      setError,
    },
    fieldArray,
  } = useMarkOrderAsReadyToInvoiceForm({
    context: {
      requirePODPhotoAndName: requirePODPhotoAndNameOverall,
    },
  });
  const stopsArr = watch('stops');

  useEffect(() => {
    if (!open) {
      return;
    }
    reset();
    stops.forEach((s) => {
      fieldArray.append({
        addressName: s.addressName,
        podSigneeName: undefined,
        pickupOrDelivery: s.stopType as string as PickupOrDelivery,
        completedDate: s.completedAt ?? s.routeDate,
        uuid: s.uuid,
        status: s.status,
        stopType: s.stopType,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit: SubmitHandler<MarkOrderAsReadyToInvoiceFormValues> = async (
    data,
  ) => {
    let isValid = true;
    const { refusedBy, refusedDate } = data;

    if (markAsRefused === true) {
      if (isEmpty(refusedBy)) {
        setError(`refusedBy`, {
          message: 'Refused by is required.',
        });
        isValid = false;
      }
      if (isNil(refusedDate)) {
        setError('refusedDate', {
          message: 'Refused date is required.',
        });
      }
    } else {
      stopsArr?.forEach((stop, index) => {
        const podSigneeNameAndDateRequired =
          (requirePODPhotoAndName === true &&
            stop.stopType === StopType.Delivery) ||
          (requirePODPhotoAndNameForPickups === true &&
            stop.stopType === StopType.Pickup);
        if (
          isNil(stop.podSigneeName) &&
          stop.status !== StopStatus.Completed &&
          podSigneeNameAndDateRequired
        ) {
          setError(`stops.${index}.podSigneeName`, {
            message: 'POD signee name is required.',
          });
          isValid = false;
        }
        if (
          isNil(stop.completedDate) &&
          stop.status !== StopStatus.Completed &&
          podSigneeNameAndDateRequired
        ) {
          setError(`stops.${index}.completedDate`, {
            message: 'Completion date is required.',
          });
          isValid = false;
        }
      });
    }

    if (!isValid) {
      return;
    }

    try {
      const completeStopBulkInput = {
        completeStopInputs: data.stops?.map((stop) => {
          return {
            stopUuid: stop.uuid,
            sentFromDriverMobileApplication: false,
            proofOfDeliverySignee: stop.podSigneeName ?? refusedBy,
            completedAt: stop.completedDate ?? refusedDate,
          };
        }),
      };
      const markOrderAsCompleteForceInput = {
        uuid: orderUuid,
        refuseOrderInput: !isNil(refusedDate)
          ? {
              uuid: orderUuid,
              refusedBy: refusedBy ?? '',
              refusedDate,
            }
          : null,
      };
      if (isNil(onSave)) {
        // We are not in form context so we don't perform the save before these updates
        const completeRes = await completeStopBulk({
          variables: {
            completeStopBulkInput,
          },
        });
        if (!isNil(completeRes.errors)) {
          throw new Error('Error bulk completing stops');
        }

        const res = await markOrderAsCompleteForce({
          variables: {
            markOrderAsCompleteForceInput,
          },
        });
        if (isNil(res.data?.markOrderAsCompleteForce) || !isNil(res.errors)) {
          throw new Error('Error marking order as complete');
        }

        setSuccessMessage('Successfully marked order as complete');
        setShowSuccessMessage(true);
      } else {
        await updateAndRefetchOrder({
          additionalUpdateFns: [
            {
              fn: completeStopBulk,
              vars: {
                completeStopBulkInput,
              },
            },
            {
              fn: markOrderAsCompleteForce,
              vars: {
                markOrderAsCompleteForceInput,
              },
            },
          ],
          onSubmit: onSave,
          showSuccess: true,
          actionString: 'completing order',
        });
      }
    } catch (e) {
      setErrorMessage('Error marking order as complete');
      setShowErrorMessage(true);
    }
    handleClose();
    reset();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: { maxHeight: 525, maxWidth: 650, minHeight: 350 },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mark order as {markAsRefused === true ? 'refused' : 'complete'}
        </Box>
      </DialogTitle>
      <DialogContent>
        {markAsRefused !== true &&
          stopsArr?.map((item, index) => {
            const routeDate = stops[index]?.routeDate;

            const showPodSigneeNameAndDateForm =
              (requirePODPhotoAndName === true &&
                item.stopType === StopType.Delivery) ||
              (requirePODPhotoAndNameForPickups === true &&
                item.stopType === StopType.Pickup) ||
              item.stopType === StopType.PartnerCarrierPickup ||
              item.stopType === StopType.PartnerCarrierDropoff;

            if (!showPodSigneeNameAndDateForm) {
              return null;
            }
            const {
              markAsCompleteSigneeInputTestId,
              markAsCompleteCompletedAtInputTestId,
            } = getMarkAsTestIds({
              stopType: item.stopType ?? StopType.None,
            });

            return (
              <Stack
                key={item.uuid}
                spacing={2}
                sx={{ marginTop: '5px', marginBottom: '25px' }}
              >
                <Typography>
                  {item.stopType === StopType.PartnerCarrierPickup ||
                  item.stopType === StopType.PartnerCarrierDropoff ? (
                    <>
                      Complete {sentenceCase(item?.stopType ?? '')}
                      <b>{item.addressName}</b> (optional)
                    </>
                  ) : (
                    <>
                      Complete {sentenceCase(item?.stopType ?? '')} to{' '}
                      <b>{item.addressName}</b>
                    </>
                  )}
                </Typography>
                <Controller
                  name={`stops.${index}.podSigneeName`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      name={`stops.[${index}].podSigneeName`}
                      size="small"
                      label="Full Signee Name"
                      value={value}
                      required={
                        item.stopType !== StopType.PartnerCarrierPickup &&
                        item.stopType !== StopType.PartnerCarrierDropoff
                      }
                      onChange={onChange}
                      error={!isNil(errors.stops?.[index]?.podSigneeName)}
                      helperText={errors.stops?.[index]?.podSigneeName?.message}
                      sx={{ width: '50%' }}
                      inputProps={{
                        'data-testid': markAsCompleteSigneeInputTestId,
                      }}
                    />
                  )}
                />
                <Controller
                  name={`stops.${index}.completedDate`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Stack direction="row" spacing={2}>
                      <StyledDatePicker
                        title="Date Completed"
                        containerClassName="completed-at-date-picker-container"
                        className="completed-at-date-picker"
                        onChange={(newDate) => {
                          onChange(
                            (newDate as DateObject)?.isValid
                              ? (newDate as DateObject).toDate().toISOString()
                              : '',
                          );
                        }}
                        value={value}
                        containerStyle={{ width: '35%' }}
                        plugins={[
                          <TimePicker
                            key="time-picker"
                            position="right"
                            hideSeconds
                            format="HH:mm"
                          />,
                        ]}
                        calendarPosition="right"
                        hideOnScroll
                        style={{
                          width: '100%',
                          fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                          fontSize: '1rem',
                          color: 'rgba(0, 0, 0, 0.87)',
                          borderColor: !isNil(
                            errors.stops?.[index]?.completedDate,
                          )
                            ? '#B00020'
                            : 'rgba(0, 0, 0, 0.23)',
                        }}
                        render={
                          <DatePickerInput
                            placeholder="Completed Date"
                            dataTestId={markAsCompleteCompletedAtInputTestId}
                          />
                        }
                        name="Date Completed"
                        format="MM/DD/YYYY HH:mm"
                      />
                      {!isNil(errors.stops?.[index]?.completedDate) && (
                        <FormHelperText sx={{ color: '#B00020' }}>
                          {errors.stops?.[index]?.completedDate?.message}
                        </FormHelperText>
                      )}
                      {!isNil(routeDate) && typeof value !== 'object' && (
                        <Button
                          onClick={() => {
                            onChange(dayjs(routeDate).startOf('day').toDate());
                          }}
                        >
                          Use Route Date
                        </Button>
                      )}
                    </Stack>
                  )}
                />
              </Stack>
            );
          })}
        {markAsRefused === true && (
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            <Controller
              name="refusedBy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  name="refusedBy"
                  size="small"
                  label="Refused by"
                  value={value}
                  required
                  onChange={onChange}
                  error={!isNil(errors.refusedBy)}
                  helperText={errors.refusedBy?.message}
                  sx={{ width: '50%' }}
                />
              )}
            />
            <Stack>
              <Controller
                name="refusedDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    title="Date Refused"
                    onChange={(newDate) => {
                      onChange(
                        (newDate as DateObject)?.isValid
                          ? (newDate as DateObject).toDate().toISOString()
                          : '',
                      );
                    }}
                    value={value}
                    plugins={[
                      <TimePicker
                        key="time-picker"
                        position="right"
                        hideSeconds
                        format="HH:mm"
                      />,
                    ]}
                    containerStyle={{ width: '35%' }}
                    calendarPosition="right"
                    hideOnScroll
                    style={{
                      width: '100%',
                      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                      fontSize: '1rem',
                      color: 'rgba(0, 0, 0, 0.87)',
                      borderColor: !isNil(errors.refusedDate)
                        ? '#B00020'
                        : 'rgba(0, 0, 0, 0.23)',
                    }}
                    render={<DatePickerInput placeholder="Refused Date" />}
                    name="Date Refused"
                    format="MM/DD/YYYY HH:mm"
                  />
                )}
              />
              {!isNil(errors.refusedDate) && (
                <FormHelperText sx={{ color: '#B00020' }}>
                  {errors.refusedDate?.message}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions
        sx={{ p: theme.spacing(2), justifyContent: 'space-between' }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={isSubmitting ? <CircularProgress size={10} /> : null}
          disabled={isSubmitting}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          data-testid={ORDER_PAGE_MARK_AS_COMPLETE_MODAL_BUTTON_TEST_ID}
        >
          Mark order as {markAsRefused === true ? 'refused' : 'complete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

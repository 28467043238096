import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  ButtonGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { SortType } from '../../../../common/components/sort-component';
import { useDriverSettlementBillsLazyQuery } from '../../../../generated/graphql';
import useDriverSettlementStore from '../../driver-settlement-store';
import { SettlementBillStatusTab } from '../../types';
import SettlementsListRow from './settlements-list-row';

const DEFAULT_SETTLEMENTS_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50];

interface SettlementsListProps {
  searchText: string;
  driverUuid: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  onTabChange: (tab: SettlementBillStatusTab) => void;
}

const SettlementsList = ({
  searchText,
  driverUuid,
  startDate,
  endDate,
  onTabChange,
}: SettlementsListProps) => {
  const [
    openedSettlementUuid,
    shouldRefreshSettlementsList,
    setShouldRefreshSettlementsList,
  ] = useDriverSettlementStore(
    (state) => [
      state.openedSettlementUuid,
      state.shouldRefreshSettlementsList,
      state.setShouldRefreshSettlementsList,
    ],
    shallow,
  );

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_SETTLEMENTS_PAGE_SIZE,
  );
  const [settlementDateSortState, setSettlementDateSortState] =
    useState<SortType>(SortType.Descending);
  const [tabIndex, setTabIndex] = useState<SettlementBillStatusTab>(
    SettlementBillStatusTab.UNFINALIZED,
  );
  const [page, setPage] = useState<number>(0);
  const [getSettlements, { data: settlementsData, loading }] =
    useDriverSettlementBillsLazyQuery();

  useEffect(() => {
    onTabChange(tabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  const handleSettlementDateSort = () => {
    if (settlementDateSortState === SortType.Ascending) {
      setSettlementDateSortState(SortType.Descending);
    } else if (settlementDateSortState === SortType.Descending) {
      setSettlementDateSortState(SortType.Ascending);
    }
  };

  const fetchSettlements = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    let isFinalized;
    if (tabIndex === SettlementBillStatusTab.UNFINALIZED) {
      isFinalized = false;
    } else if (tabIndex === SettlementBillStatusTab.FINALIZED) {
      isFinalized = true;
    }
    if (!isEmpty(searchText) && !isNil(searchText)) {
      // ignore all filters if searching
      await getSettlements({
        variables: {
          searchText: searchText.trim(),
          first,
          after,
          last,
          before,
        },
      });
    } else {
      await getSettlements({
        variables: {
          first,
          after,
          last,
          before,
          driverUuid,
          startDate,
          endDate,
          isFinalized,
          sortBySettlementDateAscending:
            settlementDateSortState === SortType.Ascending,
        },
      });
    }
  };

  const prev = async () => {
    await fetchSettlements({
      last: rowsPerPage,
      before:
        settlementsData?.driverSettlementBills.pageInfo.startCursor ??
        undefined,
    });
  };
  const next = async () => {
    await fetchSettlements({
      first: rowsPerPage,
      after:
        settlementsData?.driverSettlementBills.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = () => {
    fetchSettlements({ first: rowsPerPage });
    setPage(0);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    driverUuid,
    settlementDateSortState,
    tabIndex,
    rowsPerPage,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (shouldRefreshSettlementsList) {
      refresh();
      setShouldRefreshSettlementsList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshSettlementsList]);

  return (
    <Stack height="100%" display="flex">
      <Stack p={1}>
        <ButtonGroup
          disableElevation
          variant="outlined"
          size="small"
          aria-label="document-status-filters"
        >
          <Button
            variant={
              tabIndex === SettlementBillStatusTab.UNFINALIZED
                ? 'contained'
                : 'outlined'
            }
            onClick={() => setTabIndex(SettlementBillStatusTab.UNFINALIZED)}
          >
            Not Finalized
          </Button>
          <Button
            variant={
              tabIndex === SettlementBillStatusTab.FINALIZED
                ? 'contained'
                : 'outlined'
            }
            onClick={() => setTabIndex(SettlementBillStatusTab.FINALIZED)}
          >
            Finalized
          </Button>
          <Button
            variant={
              tabIndex === SettlementBillStatusTab.ALL
                ? 'contained'
                : 'outlined'
            }
            onClick={() => setTabIndex(SettlementBillStatusTab.ALL)}
          >
            All
          </Button>
        </ButtonGroup>
        <TablePagination
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
          labelRowsPerPage="Show"
          component="div"
          count={settlementsData?.driverSettlementBills.totalCount ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage: number) => {
            if (newPage > page) {
              next();
            } else {
              prev();
            }
            setPage(newPage);
          }}
          backIconButtonProps={{
            disabled: loading === true || page === 0,
          }}
          nextIconButtonProps={{
            disabled:
              loading === true ||
              settlementsData?.driverSettlementBills.totalCount === 0 ||
              page + 1 ===
                Math.ceil(
                  (settlementsData?.driverSettlementBills.totalCount ?? 0) /
                    rowsPerPage,
                ),
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(+e.target.value);
          }}
        />
      </Stack>
      <TableContainer sx={{ overflowY: 'scroll', flex: 1 }}>
        <Table stickyHeader aria-label="settlements-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active
                  direction={
                    settlementDateSortState === SortType.Descending
                      ? 'desc'
                      : 'asc'
                  }
                  hideSortIcon={false}
                  onClick={handleSettlementDateSort}
                >
                  Settlement date
                </TableSortLabel>
              </TableCell>
              <TableCell>Pay period</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Driver</TableCell>
              <TableCell align="right">Eligible pay</TableCell>
              <TableCell align="right">Deductions</TableCell>
              <TableCell align="right">Final pay</TableCell>
              {isNil(openedSettlementUuid) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {settlementsData?.driverSettlementBills.edges.map(
              ({ node: driverSettlementBill }) => (
                <SettlementsListRow
                  key={driverSettlementBill.uuid}
                  uuid={driverSettlementBill.uuid}
                  name={driverSettlementBill.name}
                  driverName={`${driverSettlementBill.driver.firstName} ${driverSettlementBill.driver.lastName}`}
                  driverSettlementTotal={
                    driverSettlementBill.totalDriverPayoutPreDeduction
                  }
                  billStartDate={driverSettlementBill.billStartDate}
                  billEndDate={driverSettlementBill.billEndDate}
                  settlementDate={driverSettlementBill.settlementDate}
                  settlementDeductions={
                    driverSettlementBill.settlementDeductions
                  }
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default SettlementsList;

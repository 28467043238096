import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RouteIcon from '@mui/icons-material/Route';
import {
  Button,
  CircularProgress,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { RouteFragment } from '../../../../generated/graphql';
import PalletButtonGroup from '../../../../pallet-ui/button-group/pallet-button-group';
import useDispatchStore from '../../dispatch-store';
import useRouteActions from '../../hooks/use-route-actions';
import { MapRoutePathType } from '../../types/routes';

const MAP_ROUTE_PATH_TYPE_OPTIONS = Object.values(MapRoutePathType).map(
  (value) => ({
    value,
    label: value,
  }),
);

const MapControls = React.memo(
  ({
    selectedRoutes,
    resetZoom,
  }: {
    selectedRoutes: RouteFragment[];
    resetZoom: () => void;
  }) => {
    const theme = useTheme();
    const ffDispatchTrackParity = useFeatureFlag(
      FeatureFlag.FF_DISPATCH_TRACK_PARITY,
    );
    const { generateRoutePaths } = useRouteActions();
    const [isGeneratingPaths, setIsGeneratingPaths] = useState<boolean>(false);

    const [
      showUnassignedStopsInMap,
      setShowUnassignedStopsInMap,
      mapRoutePathType,
      setMapRoutePathType,
    ] = useDispatchStore(
      (state) => [
        state.showUnassignedStopsInMap,
        state.setShowUnassignedStopsInMap,
        state.mapRoutePathType,
        state.setMapRoutePathType,
      ],
      shallow,
    );

    const generatePaths = async () => {
      setIsGeneratingPaths(true);
      await generateRoutePaths({
        uuids: selectedRoutes.map((r) => r.uuid),
      });
      setMapRoutePathType(MapRoutePathType.PATH);
      setIsGeneratingPaths(false);
    };

    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 0.5,
            px: 1,
            backgroundColor: 'white',
            zIndex: 110,
            width: '100%',
            borderBottom: 1,
            borderBottomColor: theme.palette.borderColor.main,
            boxShadow: `0px 2px 5px 0px ${theme.palette.borderColor.main}`,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">Show</Typography>
              <PalletButtonGroup
                size="small"
                aria-label="closed-status-filters"
                value={mapRoutePathType}
                onChange={setMapRoutePathType}
                options={MAP_ROUTE_PATH_TYPE_OPTIONS}
              />
            </Stack>
            <Button
              size="small"
              color="info"
              variant="contained"
              startIcon={<RouteIcon />}
              endIcon={isGeneratingPaths && <CircularProgress size={20} />}
              disabled={isGeneratingPaths}
              onClick={generatePaths}
            >
              Generate Paths
            </Button>
            {ffDispatchTrackParity && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">Show Unassigned</Typography>
                <Switch
                  checked={showUnassignedStopsInMap}
                  onChange={(e) => {
                    setShowUnassignedStopsInMap(e.target.checked);
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="contained"
              startIcon={<RestartAltIcon />}
              onClick={() => {
                resetZoom();
              }}
            >
              Reset Zoom
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

export { MapControls };

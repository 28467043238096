import { Box, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OrderFormFieldValues } from '../../forms/types';
import FormNumberInput from '../common/form-number-input';

const CashOnDelivery = () => {
  const { control } = useFormContext<OrderFormFieldValues>();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" sx={{ fontSize: '16px', marginBottom: '5px' }}>
        Collect On Delivery
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Controller
          control={control}
          name="codCheckNumber"
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth>
              <TextField
                size="small"
                value={value}
                label="Check number"
                onChange={onChange}
              />
            </FormControl>
          )}
        />
        <FormNumberInput
          fieldName="codCheckAmountInDollars"
          label="Check amount"
        />
      </Box>
    </Box>
  );
};

export default CashOnDelivery;

import { z } from 'zod';
import {
  FreightBillingMethod,
  FuelBillingMethod,
  OrderFormFieldsFragment,
  QuoteStatus,
} from '../../../../../generated/graphql';
import { lineHaulShipmentSchema } from '../../../../orders/components/order-form/forms/line-haul-shipment-schema';
import { getOrderChargesShipmentSchema } from '../../../../orders/components/order-form/forms/order-charges-shipment-schema';
import { getPackagesSchema } from '../../../../orders/components/order-form/forms/packages-schema';
import {
  StopSchemaOptions,
  getStopSchema,
} from '../../../../orders/components/order-form/forms/stop-schema';

type QuoteSchemaOptions = StopSchemaOptions & {
  orderFormFields?: OrderFormFieldsFragment;
};

export const getQuoteSchema = ({
  ffRecoveryTransferAddressOnly,
  disallowZeroDollarCharges,
  accessorials,
  orderFormFields,
}: QuoteSchemaOptions) =>
  z.object({
    uuid: z.string().uuid(),
    contactUuid: z
      .string({ required_error: 'Missing customer' })
      .uuid({ message: 'Missing customer' }),
    serviceUuid: z
      .string()
      .uuid({ message: 'Missing service level' })
      .nullish(),
    number: z.string().optional(),
    status: z.nativeEnum(QuoteStatus).nullish(),
    dimFactor: z.number().nullish(),
    stops: z
      .array(
        getStopSchema({
          disallowZeroDollarCharges,
          accessorials,
          ffRecoveryTransferAddressOnly,
        }),
      )
      .nullish(),
    packages: getPackagesSchema(orderFormFields),
    orderChargesShipment: getOrderChargesShipmentSchema({
      disallowZeroDollarCharges,
      accessorials,
    }).nullable(),
    isUsingLineHaul: z.boolean(),
    lineHaulShipment: lineHaulShipmentSchema.nullable(),
    lineHaulLaneUuid: z.string().uuid().nullish(),
    totalSkids: z.number().nullish(),
    defaultFuelBillingMethod: z.nativeEnum(FuelBillingMethod).optional(),
    defaultFuelSurcharge: z.number().optional(),
    defaultFreightBillingMethod: z.nativeEnum(FreightBillingMethod).optional(),
    useKilograms: z.boolean().nullish(),
    useCentimeters: z.boolean().nullish(),
  });

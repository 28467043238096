import SearchIcon from '@mui/icons-material/Search';
import { Box, Stack, Typography, Button } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { useState } from 'react';
import { useIsMac } from '../../../common/react-hooks/use-is-mac';
import UnifiedSearchDialog from '../../../domains/orders/components/unified-search/unified-search-dialog';
import theme from '../../../theme';

export default function NavbarSearchItem() {
  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);
  const isMac = useIsMac();
  useHotkeys(['Control+k', 'Meta+k'], async (e) => {
    e.preventDefault();
    setSearchDialogOpen(true);
  });

  return (
    <>
      <Button
        onClick={() => setSearchDialogOpen(true)}
        sx={{ padding: 0, width: '100%' }}
      >
        <Stack
          flexGrow={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          border="1px solid #FDFDFD1F"
          bgcolor="#FDFDFD14"
          padding="8px 12px 8px 8px"
          borderRadius="8100px"
        >
          <Box display="flex" alignItems="center" gap="8px">
            <SearchIcon
              sx={{ color: theme.palette.concreteGrey[50], width: 16 }}
            />
            <Typography
              variant="caption"
              color={theme.palette.concreteGrey[10]}
              fontSize="14px"
            >
              Search orders
            </Typography>
          </Box>
          <Typography
            variant="caption"
            color={theme.palette.concreteGrey[50]}
            fontSize="10px"
          >
            {isMac ? '⌘+K' : 'CTRL+K'}
          </Typography>
        </Stack>
      </Button>
      {searchDialogOpen && (
        <UnifiedSearchDialog
          open={searchDialogOpen}
          setOpen={setSearchDialogOpen}
        />
      )}
    </>
  );
}

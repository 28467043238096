import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Stack, Typography } from '@mui/material';
import { isEmpty, mapKeys, omit } from 'lodash';
import React from 'react';
import {
  useFormContext,
  useFormState,
  get,
  FieldErrors,
} from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import { isNilOrEmptyString } from '../../../../../common/utils/utils';
import PalletModalNew from '../../../../../pallet-ui/modal/pallet-modal';
import { StopValues, CustomChargeValues } from '../forms/types';
import { OrderFormFieldValues } from '../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from './constants';

const isFieldError = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error !== null && 'message' in error;
};

/**
 * Recursively find any RHF error messages in `formErrors`.
 * Typing is very loose (`object`) because the RHF FieldErrors type is hard to work with
 */
const getAllErrorMessages = (formErrors: object): string[] => {
  const messages: string[] = [];
  const readErrors = (errors: object) => {
    Object.values(errors).forEach((value) => {
      if (isFieldError(value) && !isNilOrEmptyString(value.message)) {
        messages.push(value.message);
      } else if (typeof value === 'object' && value !== null) {
        readErrors(value);
      }
    });
  };
  readErrors(formErrors);
  return messages;
};

export enum OrderFormType {
  Order = 'ORDER',
  Quote = 'QUOTE',
}

const getOrderFormTypeLabel = (type: OrderFormType) => {
  switch (type) {
    case OrderFormType.Order:
      return 'order';
    case OrderFormType.Quote:
      return 'quote';
    default:
      return exhaustive(type);
  }
};

type TransformedOrderFormErrors = Omit<
  FieldErrors<OrderFormFieldValues>,
  'stops'
> & {
  inbound: FieldErrors<Omit<StopValues, 'customCharges'>>;
  outbound: FieldErrors<Omit<StopValues, 'customCharges'>>;
  charges: FieldErrors<CustomChargeValues>;
};

const getErrorSectionTitle = (key: keyof TransformedOrderFormErrors) => {
  switch (key) {
    case 'inbound':
      return 'Inbound ';
    case 'outbound':
      return 'Outbound';
    case 'packages':
      return 'Packages';
    case 'charges':
      return 'Charges';
    case 'orderChargesShipment':
      return 'Order charges';
    case 'lineHaulShipment':
      return 'Line haul';
    case 'uuid':
    case 'contactUuid':
    case 'status':
    case 'useKilograms':
    case 'useCentimeters':
    case 'serviceUuid':
    case 'dimFactor':
    case 'defaultFuelBillingMethod':
    case 'defaultFuelSurcharge':
    case 'defaultFreightBillingMethod':
    case 'isUsingLineHaul':
    case 'lineHaulLaneUuid':
    case 'totalSkids':
    case 'root':
      // These are top-level fields that won't be in a section
      return '';
    default:
      return exhaustive(key);
  }
};

type OrderFormErrorDialogProps = {
  handleClose: () => void;
  open: boolean;
  type: OrderFormType;
  additionalErrors?: string[];
};

const OrderFormErrorDialog = ({
  handleClose,
  open,
  type,
  additionalErrors,
}: OrderFormErrorDialogProps) => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const { errors: rawErrors } = useFormState<OrderFormFieldValues>({ control });

  // Transform errors so that they better correspond to the UI
  // 1. Move stops array to "inboundStop" and "outboundStop"
  // 2. Add "charges" section with charges from both stops
  const errorsWithoutStops = omit(rawErrors, 'stops');
  const inboundStopErrors = omit(
    get(rawErrors, `stops.${INBOUND_STOP_IDX}`),
    'customCharges',
  );
  const outboundStopErrors = omit(
    get(rawErrors, `stops.${OUTBOUND_STOP_IDX}`),
    'customCharges',
  );
  const inboundStopChargesErrors = get(
    rawErrors,
    `stops.${INBOUND_STOP_IDX}.customCharges`,
  );
  const outboundStopChargesErrors = get(
    rawErrors,
    `stops.${OUTBOUND_STOP_IDX}.customCharges`,
  );
  const errors: TransformedOrderFormErrors = {
    ...errorsWithoutStops,
    inbound: inboundStopErrors,
    outbound: outboundStopErrors,
    charges: {
      ...mapKeys(inboundStopChargesErrors, (_, key) => `inbound_${key}`),
      ...mapKeys(outboundStopChargesErrors, (_, key) => `outbound_${key}`),
    },
  };

  const errorsList = Object.entries(errors).map(([key, value]) => {
    // Handle top-level/root errors
    if (isFieldError(value) && !isNilOrEmptyString(value.message)) {
      return (
        <Typography key={key} data-testid="form-error">
          {value.message}
        </Typography>
      );
    }

    // We have sub-sections of the form errors object (e.g. `inbound` stop)
    // that might be even more deeply nested (e.g. `customCharges` or `address`),
    // but we only want to show a flat list of error messages.
    const messages = getAllErrorMessages(value);
    if (isEmpty(messages)) {
      return null;
    }
    return (
      <Box key={key}>
        <Typography fontWeight="bold" data-testid="form-error-section-title">
          {getErrorSectionTitle(key as keyof TransformedOrderFormErrors)}
        </Typography>
        {messages.map((message) => (
          <Typography key={message} data-testid="form-error">
            {message}
          </Typography>
        ))}
      </Box>
    );
  });

  return (
    <PalletModalNew
      open={open}
      onClose={handleClose}
      hideCloseButton
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <ErrorIcon color="error" />
          Errors in saving this {getOrderFormTypeLabel(type)}
        </Stack>
      }
      pinnedElements={{
        bottomRight: (
          <Button variant="contained" onClick={handleClose}>
            OK
          </Button>
        ),
      }}
    >
      <Stack direction="column" alignItems="flex-start" gap={1} height="100%">
        {additionalErrors?.map((error, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography key={`${error}-${idx}`} data-testid="additional-error">
            {error}
          </Typography>
        ))}
        {errorsList}
      </Stack>
    </PalletModalNew>
  );
};

export default OrderFormErrorDialog;

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import React, { ElementType, PropsWithChildren } from 'react';
import theme from '../../theme';

/**
 * If allowOverflow is true, the dialog will allow its children to overflow
 * the dialog container. This is useful for absolutely positioning elements
 * outside of the dialog container.
 *
 * e.x. usage in DocumentViewerModal which allows floating next and previous toggle
 * buttons.
 *
 * @param allowOverflow
 * @returns
 */
const getDialogPaperProps = (
  allowOverflow: boolean,
): Partial<PaperProps<ElementType>> => ({
  style: {
    overflow: allowOverflow ? 'visible' : 'hidden',
  },
});

interface PalletModalNewProps {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  hideCloseButton?: boolean;
  disableCloseButton?: boolean;
  allowOverflow?: boolean;
  pinnedElements?: {
    topRight?: React.ReactNode;
    bottomRight?: React.ReactNode;
    bottomLeft?: React.ReactNode;
  };
}

const PalletModalNew = ({
  open,
  onClose,
  title,
  hideCloseButton = false,
  disableCloseButton,
  allowOverflow = false,
  pinnedElements,
  children,
}: PropsWithChildren<PalletModalNewProps>) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      PaperProps={getDialogPaperProps(allowOverflow)}
      maxWidth={false}
    >
      <DialogTitle
        id="dialog-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {typeof title === 'string' ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          <Box>{title}</Box>
        )}
        <Stack flexDirection="row" gap={1}>
          <Box>{pinnedElements?.topRight}</Box>
          {!hideCloseButton && (
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
              disabled={disableCloseButton}
              sx={{ color: theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'auto' }}>{children}</DialogContent>
      <DialogActions>
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>{pinnedElements?.bottomLeft}</Box>
          <Box>{pinnedElements?.bottomRight}</Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PalletModalNew;

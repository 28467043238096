import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getStopRecoveryLocationTestIds } from '../../../../../../../utils';
import useRecoveryLocationInfos from '../../../../../../common/react-hooks/use-recovery-location-infos';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import RecoveryTerminalsModal from '../../../../../management/components/recovery-terminals-modal';
import CreateOrEdit from '../../../../../management/enums/create-or-edit';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { StopType } from '../../forms/stop-type';
import { OrderFormFieldValues } from '../../forms/types';
import { stopTypeLabel } from '../../utils';

const ADD_NEW_RECOVERY_LOCATION_OPTION = 'ADD_NEW_RECOVERY_LOCATION';

interface StopLocationInfoProps {
  index: number;
  stopType: StopType.Recovery | StopType.Transfer;
}

const StopLocationInfo = ({ index, stopType }: StopLocationInfoProps) => {
  const [showAddNewStopLocationModal, setShowAddNewStopLocationModal] =
    useState(false);
  const { recoveryLocationInfos } = useRecoveryLocationInfos();
  const { control, setValue, clearErrors } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });
  const recoveryLocationInfoUuid = useWatch({
    control,
    name: `stops.${index}.airportInfoUuid`,
  });
  const recoveryLocationOption = recoveryLocationInfos.find(
    (location) => location.uuid === recoveryLocationInfoUuid,
  );
  const recoveryLocationOptions = useMemo(() => {
    return (recoveryLocationInfos ?? [])
      .map((recoveryLocationInfo) => ({
        value: recoveryLocationInfo.uuid,
        label: recoveryLocationInfo.terminal,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [recoveryLocationInfos]);
  const address = useWatch({ control, name: `stops.${index}.address` });

  const { recoveryLocationSelectTestId, recoveryLocationSelectInputTestId } =
    getStopRecoveryLocationTestIds({ stopIdx: index });

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      <RecoveryTerminalsModal
        createOrEdit={CreateOrEdit.Create}
        open={showAddNewStopLocationModal}
        onClose={() => {
          setShowAddNewStopLocationModal(false);
        }}
        selectedRecoveryTerminal={undefined}
        onCreated={(recoveryTerminal) => {
          setValue(`stops.${index}.airportInfoUuid`, recoveryTerminal.uuid);
          setValue(`stops.${index}.address`, recoveryTerminal.address);
        }}
      />
      <Controller
        name={`stops.${index}.airportInfoUuid`}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <AutocompleteFuzzy
              {...field}
              size="small"
              value={
                !isNil(recoveryLocationInfoUuid)
                  ? {
                      value: recoveryLocationInfoUuid,
                      label: recoveryLocationOption?.terminal ?? '',
                    }
                  : null
              }
              options={recoveryLocationOptions}
              stickyOptionProps={{
                stickyOption: {
                  value: ADD_NEW_RECOVERY_LOCATION_OPTION,
                  label: `+ ${sentenceCase(ADD_NEW_RECOVERY_LOCATION_OPTION)}`,
                },
                filterStickyOption: false,
                stickyOptionPosition: 'last',
              }}
              matchSortOptions={{ keys: ['label'] }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              data-testid={recoveryLocationSelectTestId}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${stopTypeLabel(stopType)} location`}
                  error={!isNil(errors?.stops?.[index]?.airportInfoUuid)}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': recoveryLocationSelectInputTestId,
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              onChange={(_event, option) => {
                clearErrors();
                if (option?.value === ADD_NEW_RECOVERY_LOCATION_OPTION) {
                  setShowAddNewStopLocationModal(true);
                } else if (isNil(option)) {
                  field.onChange(null);
                  setValue(`stops.${index}.address`, null);
                } else {
                  const newRecoveryLocation = recoveryLocationInfos.find(
                    (location) => location.uuid === option.value,
                  );
                  if (!isNil(newRecoveryLocation)) {
                    field.onChange(option.value);
                    setValue(
                      `stops.${index}.address`,
                      newRecoveryLocation.address,
                    );
                  }
                }
              }}
              disabled={disabledIfFinalizedOrLater}
              autoHighlight
              autoSelect
            />
            {!isNil(errors?.stops?.[index]?.airportInfoUuid?.message) && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                {errors?.stops?.[index]?.airportInfoUuid?.message?.toString()}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
      {!isNil(address) && !isNil(recoveryLocationInfoUuid) && (
        <Typography fontSize="14px">
          {transformAddressToFullAddressString(address)}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(StopLocationInfo);

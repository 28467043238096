import { isNil } from 'lodash';
import { FieldPath } from 'react-hook-form';
import { z } from 'zod';

/**
 * Utility type that accepts a `Date` or an ISO datetime string.
 *
 * The DateTime GraphQL scalar that we use serializes `Date`s as strings, but TypeScript
 * types them as `any`, so there are places on the frontend where we accidentally use
 * them as `Date`s when they're actually strings.
 *
 * We can't just use zDateOrDatetimeString because that will accept null values
 * too, which coerce to 1970-01-01.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const zDateOrDatetimeString = (params?: { required_error: string }) =>
  z.union(
    [
      z.date(),
      z
        .string()
        .datetime()
        .transform((val) => new Date(val)),
    ],
    params,
  );

/** Recursively removes Zod effects, optional, and nullable from a schema */
export const unwrapSchema = <T extends z.ZodTypeAny>(
  schema: T,
  allowOptional?: true,
): T => {
  let unwrappedSchema = schema;

  while (
    unwrappedSchema instanceof z.ZodEffects ||
    unwrappedSchema instanceof z.ZodNullable ||
    (unwrappedSchema instanceof z.ZodOptional && !allowOptional)
  ) {
    if (unwrappedSchema instanceof z.ZodEffects) {
      unwrappedSchema = unwrappedSchema.innerType();
    } else {
      unwrappedSchema = unwrappedSchema.unwrap();
    }
  }

  return unwrappedSchema;
};

/** Returns true if the field is present in the schema */
export const getIsFieldVisible =
  <T extends z.ZodTypeAny>(schema: T) =>
  (key: FieldPath<z.infer<T>>) => {
    const parts = key.split('.');
    let currentSchema = unwrapSchema(schema);

    for (const part of parts) {
      if (!(currentSchema instanceof z.ZodObject)) {
        if (
          currentSchema instanceof z.ZodArray &&
          !Number.isNaN(Number(part))
        ) {
          currentSchema = unwrapSchema(currentSchema.element);
          continue;
        }
        return false;
      }

      const field = currentSchema.shape[part];
      if (isNil(field)) {
        return false;
      }

      currentSchema = unwrapSchema(field);
    }

    return true;
  };

/** Returns true if the field is present and required in the schema */
export const getIsFieldRequired =
  <T extends z.ZodTypeAny>(schema: T) =>
  (key: FieldPath<z.infer<T>>) => {
    const parts = key.split('.');
    let currentSchema = schema;

    for (const part of parts) {
      currentSchema = unwrapSchema(currentSchema);

      if (currentSchema instanceof z.ZodArray && !Number.isNaN(Number(part))) {
        currentSchema = unwrapSchema(currentSchema.element);
        continue;
      }

      if (!(currentSchema instanceof z.ZodObject)) {
        return false;
      }

      currentSchema = currentSchema.shape[part];
      if (isNil(currentSchema)) {
        return false;
      }
    }

    currentSchema = unwrapSchema(currentSchema, true);
    return !(currentSchema instanceof z.ZodOptional);
  };

import { Alert, Box, GlobalStyles, Snackbar, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { CSSProperties, ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import GlobalSnackbars from '../../common/components/global-snackbars/global-snackbars';
import SharedModalContainer from '../../common/components/modals/shared-modal-container';
import { FeatureFlag } from '../../common/feature-flags';
import useContacts from '../../common/react-hooks/use-contacts';
import useDrivers from '../../common/react-hooks/use-drivers';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useServices from '../../common/react-hooks/use-services';
import useTerminals from '../../common/react-hooks/use-terminals';
import useDispatchStore from '../../domains/dispatch/dispatch-store';
import { AppBarHeader } from './app-bar';
import useGlobalStore from './global-store';
import { Sidebar } from './sidebar';
import { DashboardPage } from './types';
import useDrawerItems from './use-drawer-items';

const GlobalAgGridFontStyles = () => {
  const ffNewFont = useFeatureFlag(FeatureFlag.FF_NEW_FONT);
  if (!ffNewFont) {
    return null;
  }
  return (
    <GlobalStyles
      styles={{
        // Repeating the selector is a hack to increase specificity
        '.ag-theme-material.ag-theme-material': {
          fontFamily: 'var(--pallet-font-family)',
        },
      }}
    />
  );
};

const DASHBOARD_STYLES: { [key: string]: CSSProperties } = {
  dashboardBox: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    minWidth: 0,
  },
};

interface DashboardLayoutProps {
  children: ReactNode;
  page: DashboardPage;
}

export default function DashboardLayout({
  children,
  page,
}: DashboardLayoutProps) {
  // TODO: This is a temporary fix to ensure that the new dashboard UI works for the contacts and company setup pages.
  // We should remove this once we have a more permanent solution for this.
  const effectivePage =
    page === DashboardPage.CONTACTS || page === DashboardPage.COMPANYSETUP
      ? DashboardPage.SETTINGS
      : page;

  const { companyUuid, user } = useMe();
  const { drawerItems } = useDrawerItems();
  const [searchParams] = useSearchParams();
  const orderUuidSearchParam = searchParams.get('orderUuid');

  const [
    userShouldRefresh,
    forceRefresh,
    setSelectedTerminalUuid,
    setOpenedOrderUuid,
  ] = useGlobalStore(
    (state) => [
      state.userShouldRefresh,
      state.forceRefresh,
      state.setSelectedTerminalUuid,
      state.setCurrentOrderUuid,
    ],
    shallow,
  );
  const [
    setUseMinimizedAppointmentTime,
    setNumberOfStops,
    setRouteCardWidth,
    setArrangeStopsAndRoutesVertically,
  ] = useDispatchStore(
    (state) => [
      state.setUseMinimizedAppointmentTime,
      state.setNumberOfStops,
      state.setRouteCardWidth,
      state.setArrangeStopsAndRoutesVertically,
    ],
    shallow,
  );
  useEffect(() => {
    if (isNilOrEmptyString(orderUuidSearchParam)) {
      setOpenedOrderUuid(undefined);
    }
  }, [orderUuidSearchParam, setOpenedOrderUuid]);

  const { loadContacts } = useContacts();
  const { loadTerminals, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { loadServices } = useServices();
  const { loadDrivers } = useDrivers();
  const ffShowNewDispatchPage = useFeatureFlag(
    FeatureFlag.FF_NEW_DISPATCH_PAGE,
  );

  useEffect(() => {
    if (!isNil(user)) {
      setUseMinimizedAppointmentTime(user.useMinimizedAppointmentTime);
      setNumberOfStops(user.numberOfStops);
      setRouteCardWidth(user.routeCardWidth);
      if (terminalsEnabled) {
        setSelectedTerminalUuid(user.terminal?.uuid ?? undefined);
      } else {
        setSelectedTerminalUuid(undefined);
      }
      setArrangeStopsAndRoutesVertically(
        user.arrangeStopsAndRoutesVertically === true &&
          !isNil(ffShowNewDispatchPage) &&
          ffShowNewDispatchPage,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ffShowNewDispatchPage, terminalsEnabled]);

  const selectedDrawerItem = drawerItems.find(
    (item) => item.page === effectivePage,
  );

  useEffect(() => {
    if (forceRefresh) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh]);

  useEffect(() => {
    loadContacts();
    loadTerminals();
    loadServices();
    loadDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUuid]);

  return (
    <Stack
      sx={{
        backgroundColor: '#F7F7F7',
        minWidth: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={userShouldRefresh}
      >
        <Alert severity="warning">
          A new version of the app has been released. Please refresh.
        </Alert>
      </Snackbar>
      <GlobalAgGridFontStyles />
      <SharedModalContainer />
      <GlobalSnackbars />

      <Stack direction="row" flex="1" minHeight="0">
        <Sidebar />
        <Box
          sx={{
            ...DASHBOARD_STYLES.dashboardBox,
            ...{
              flexDirection: 'column',
              overflow: 'initial',
            },
          }}
        >
          <AppBarHeader
            page={effectivePage}
            selectedDrawerItem={selectedDrawerItem}
          />
          <Box flex={1} minHeight={0}>
            {children}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

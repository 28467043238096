import { CircularProgress, Stack, SxProps } from '@mui/material';
import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { AutocompletePerson } from '../../../standard/components/autocomplete-person';
import { StopMethod } from '../../forms/stop-type';
import { OrderFormFieldValues } from '../../types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../constants';
import StopDetails from './stop-details';

const stopSx: SxProps = {
  flexBasis: {
    xs: '100%',
    xl: '50%',
  },
};

const Stops = ({
  isQuotePage,
  forceEditMode,
  recurringTemplate,
  showDocumentComponent,
  isEditMode,
  autocompletePerson,
  setAutocompletePerson,
}: {
  isQuotePage: boolean;
  forceEditMode?: boolean;
  recurringTemplate?: boolean;
  showDocumentComponent?: boolean;
  isEditMode: boolean;
  autocompletePerson: AutocompletePerson | null;
  setAutocompletePerson: (person: AutocompletePerson | null) => void;
}) => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const inboundStopUuid = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.uuid`,
  });
  const outboundStopUuid = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.uuid`,
  });

  if (isNil(inboundStopUuid) || isNil(outboundStopUuid)) {
    return (
      <Stack bgcolor="white" alignItems="center" py={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column',
          xl: 'row',
        },
        alignItems: 'stretch',
        gap: '1px',
      }}
    >
      <StopDetails
        key={inboundStopUuid}
        index={INBOUND_STOP_IDX}
        method={StopMethod.Inbound}
        otherStopIndex={OUTBOUND_STOP_IDX}
        isQuotePage={isQuotePage}
        forceEditMode={forceEditMode}
        recurringTemplate={recurringTemplate}
        showDocumentComponent={showDocumentComponent}
        isEditMode={isEditMode}
        autocompletePerson={autocompletePerson}
        setAutocompletePerson={setAutocompletePerson}
        sx={stopSx}
      />
      <StopDetails
        key={outboundStopUuid}
        index={OUTBOUND_STOP_IDX}
        method={StopMethod.Outbound}
        otherStopIndex={INBOUND_STOP_IDX}
        isQuotePage={isQuotePage}
        forceEditMode={forceEditMode}
        recurringTemplate={recurringTemplate}
        showDocumentComponent={showDocumentComponent}
        isEditMode={isEditMode}
        autocompletePerson={autocompletePerson}
        setAutocompletePerson={setAutocompletePerson}
        sx={stopSx}
      />
    </Stack>
  );
};

export default Stops;

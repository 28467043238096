import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEqual, isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  StopType as _StopType,
  AllAccessorialFieldsFragment,
  FreightBillingMethod,
  FulfillmentType,
  ShipmentType,
  StopStatus,
} from '../../../../generated/graphql';
import { dummyStopTypes, isCrossDock } from '../../lib/stop-types';
import {
  StopType,
  convertOrderFormStopTypeToStopType,
} from './forms/stop-type';
import {
  LineHaulShipmentValues,
  OrderChargesShipmentValues,
  OrderFormValues,
  StopValues,
} from './forms/types';
import { OrderShipmentContext, ShipmentPresenceStatus } from './types';

const STOP_TYPES_WITHOUT_FREIGHT_CHARGES: _StopType[] = [
  _StopType.Recovery,
  _StopType.PartnerCarrierDropoff,
  _StopType.PartnerCarrierPickup,
];

export const stopTypeLabel = (stopType: StopType): string => {
  switch (stopType) {
    case StopType.PartnerCarrierPickup:
      return 'Carrier pickup';
    case StopType.PartnerCarrierDropoff:
      return 'Carrier dropoff';
    case StopType.Transfer:
      return 'Transfer';
    default:
      return sentenceCase(stopType);
  }
};

/**
 * Given a list of accessorials and a possible terminal UUID, returns a list of accessorials that
 * can be applied to the stop.
 * If terminalUuid is not null, it will return accessorials that are:
 * 1. global and applicable to all terminals,
 * 2. global and applicable to specified terminal
 * 3. contact-specific and applicable to all terminals.
 * 4. contact-specific and applicable to specified terminal.
 * If terminalUuid is null, it will return the list of accessorials as is.
 * @param accessorials
 * @param terminalUuids
 */
export function filterAccessorialOptionsForTerminal(
  accessorials: AllAccessorialFieldsFragment[],
  terminalUuids?: (string | null | undefined)[],
): AllAccessorialFieldsFragment[] {
  if (
    isNil(terminalUuids) ||
    terminalUuids.length === 0 ||
    terminalUuids.includes(null) ||
    terminalUuids.includes(undefined)
  ) {
    // If one of the terminals isn't selected yet, return all accessorials
    return accessorials;
  }
  return accessorials.filter((accessorial) => {
    const accTerminalUuid = accessorial?.terminal?.uuid;
    return (
      isNilOrEmptyString(accTerminalUuid) ||
      terminalUuids.includes(accTerminalUuid)
    );
  });
}

export function normalizedStringEquals(a: string, b: string): boolean {
  return a.trim().toLowerCase() === b.trim().toLowerCase();
}

export function getInboundStop(
  stopValues: StopValues[],
): StopValues | undefined {
  return stopValues.find(
    (s) =>
      s.stopType === StopType.Pickup ||
      s.stopType === StopType.PartnerCarrierDropoff ||
      s.stopType === StopType.Recovery,
  );
}

export function getOutboundStop(
  stopValues: StopValues[],
): StopValues | undefined {
  return stopValues.find(
    (s) =>
      s.stopType === StopType.Delivery ||
      s.stopType === StopType.Transfer ||
      s.stopType === StopType.PartnerCarrierPickup,
  );
}

export function stopCanBeMarkedIncomplete({
  stopStatus,
}: {
  stopStatus: StopStatus | null | undefined;
}): boolean {
  switch (stopStatus) {
    case StopStatus.Cancelled:
    case StopStatus.Failed:
    case StopStatus.NotArrived:
    case null:
    case undefined:
      return false;
    case StopStatus.Arrived:
    case StopStatus.Completed:
      return true;
    default:
      return exhaustive(stopStatus);
  }
}

export function stopCanBeMarkedAsArrived({
  stopType,
  stopStatus,
  routeUuid,
}: {
  stopType: StopType | null | undefined;
  stopStatus: StopStatus | null | undefined;
  routeUuid: string | null | undefined;
}): boolean {
  if (
    isNil(routeUuid) ||
    stopStatus === StopStatus.Completed ||
    stopStatus === StopStatus.Arrived
  ) {
    return false;
  }
  switch (stopType) {
    case StopType.None:
    case null:
    case undefined:
      return false;
    case StopType.Delivery:
    case StopType.Pickup:
    case StopType.Recovery:
    case StopType.Transfer:
    case StopType.PartnerCarrierPickup:
    case StopType.PartnerCarrierDropoff:
      return true;
    default:
      return exhaustive(stopType);
  }
}

export function stopCanBeCompleted({
  stopType,
  shipperBillOfLadingNumber,
  stopStatus,
}: {
  stopType: StopType | null | undefined;
  shipperBillOfLadingNumber: string | null | undefined;
  stopStatus: StopStatus | null | undefined;
}): boolean {
  if (isNil(shipperBillOfLadingNumber)) {
    return false;
  }
  if (stopStatus === StopStatus.Completed) {
    return false;
  }
  switch (stopType) {
    case StopType.None:
    case null:
    case undefined:
      return false;
    case StopType.Delivery:
    case StopType.Pickup:
    case StopType.Recovery:
    case StopType.Transfer:
    case StopType.PartnerCarrierPickup:
    case StopType.PartnerCarrierDropoff:
      return true;
    default:
      return exhaustive(stopType);
  }
}

export function isValidStopTypeForSpecialAccessorialCharges(
  stopType: StopType,
) {
  switch (stopType) {
    case StopType.Pickup:
    case StopType.Delivery:
      return true;
    case StopType.PartnerCarrierPickup:
    case StopType.PartnerCarrierDropoff:
    case StopType.Transfer:
    case StopType.Recovery:
    case StopType.None:
      return false;
    default:
      return exhaustive(stopType);
  }
}

export const shouldShowTariffWarning = ({
  inboundStop,
  outboundStop,
}: {
  inboundStop?: StopValues | undefined;
  outboundStop?: StopValues | undefined;
}) => {
  const inboundStopType = !isNil(inboundStop?.stopType)
    ? convertOrderFormStopTypeToStopType(inboundStop?.stopType)
    : undefined;
  const outboundStopType = !isNil(outboundStop?.stopType)
    ? convertOrderFormStopTypeToStopType(outboundStop?.stopType)
    : undefined;
  if (
    inboundStop?.freightCharge?.billingMethod === FreightBillingMethod.Tariff &&
    isNil(inboundStop?.freightCharge.tariffUuid) &&
    !isNil(inboundStopType) &&
    !STOP_TYPES_WITHOUT_FREIGHT_CHARGES.includes(inboundStopType)
  )
    return true;
  if (
    outboundStop?.freightCharge?.billingMethod ===
      FreightBillingMethod.Tariff &&
    isNil(outboundStop?.freightCharge.tariffUuid) &&
    !isNil(outboundStopType) &&
    !STOP_TYPES_WITHOUT_FREIGHT_CHARGES.includes(outboundStopType)
  ) {
    // do not show warning if the outbound stop is a transfer with no destination
    if (
      !(
        outboundStopType === _StopType.Transfer &&
        outboundStop.destinationInOutbound !== true
      )
    ) {
      return true;
    }
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCompareOrderChanges = (obj1: any, obj2: any): boolean => {
  // If both objects are null or not of type 'object', compare them directly
  if (
    isNil(obj1) ||
    isNil(obj2) ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  ) {
    return (
      isEqual(obj1, obj2) ||
      (obj1 === 0 && isNil(obj2)) ||
      (obj2 === 0 && isNil(obj1))
    );
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1 ?? {});
  const keys2 = Object.keys(obj2 ?? {});
  const keys = keys1.length > keys2.length ? keys1 : keys2;

  // Iterate over keys and recursively compare values
  for (const key of keys) {
    // If the key is 'totalCharge', disregard it
    if (
      key === 'totalCharge' ||
      key === 'settlementTotal' ||
      key === 'deductionTotal' ||
      key === 'errorMessage'
    ) {
      continue;
    }

    // Recursively compare nested objects
    if (!deepCompareOrderChanges(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const shipmentHasCharges = (
  shipment:
    | StopValues
    | LineHaulShipmentValues
    | OrderChargesShipmentValues
    | null
    | undefined,
) => {
  if (isNil(shipment)) {
    return false;
  }

  return (
    !isNil(shipment.freightCharge) ||
    ('customCharges' in shipment &&
      !isNil(shipment.customCharges) &&
      shipment.customCharges.length > 0)
  );
};

const getCurrentShipmentStatus = (
  shipment:
    | StopValues
    | LineHaulShipmentValues
    | OrderChargesShipmentValues
    | null
    | undefined,
): ShipmentPresenceStatus => {
  if (isNil(shipment)) {
    return {
      present: false,
    };
  }
  return {
    present: true,
    freightChargePresent: !isNil(shipment.freightCharge),
  };
};

export function getExpectedRegularShipmentsStatus({
  stopType,
  fulfillmentType,
}: {
  stopType: StopType | null | undefined;
  fulfillmentType: FulfillmentType | null | undefined;
}): ShipmentPresenceStatus {
  const isValidStopForCharges =
    !isNil(stopType) && !dummyStopTypes.includes(stopType);
  return {
    present: true,
    freightChargePresent:
      fulfillmentType !== FulfillmentType.Dedicated && isValidStopForCharges,
  };
}

export function getExpectedOrderChargesShipmentStatus({
  fulfillmentType,
  inboundStopType,
  outboundStopType,
}: {
  fulfillmentType: FulfillmentType | null | undefined;
  inboundStopType: StopType | null | undefined;
  outboundStopType: StopType | null | undefined;
}): ShipmentPresenceStatus {
  if (fulfillmentType === FulfillmentType.Dedicated) {
    return {
      present: true,
      freightChargePresent: true,
    };
  }

  const hasPartnerCarrierStop =
    inboundStopType === StopType.PartnerCarrierDropoff ||
    outboundStopType === StopType.PartnerCarrierPickup;

  if (isCrossDock(inboundStopType, outboundStopType) || hasPartnerCarrierStop) {
    return {
      present: true,
      freightChargePresent: false,
    };
  }
  return {
    present: false,
  };
}

export function getExpectedLineHaulShipmentStatus(
  isUsingLineHaul: boolean,
): ShipmentPresenceStatus {
  if (isUsingLineHaul) {
    return {
      present: true,
      freightChargePresent: true,
    };
  }
  return {
    present: false,
  };
}

const ENABLE_DEBUG_LOG = false;

const logDifferences = ({
  currentInboundShipmentStatus,
  currentOutboundShipmentStatus,
  currentLineHaulShipmentStatus,
  currentOrderChargesShipmentStatus,
  expectedInboundShipmentsStatus,
  expectedOutboundShipmentsStatus,

  expectedLineHaulShipmentStatus,
  expectedOrderChargesShipmentStatus,
}: {
  currentInboundShipmentStatus: ShipmentPresenceStatus;
  currentOutboundShipmentStatus: ShipmentPresenceStatus;
  currentLineHaulShipmentStatus: ShipmentPresenceStatus;
  currentOrderChargesShipmentStatus: ShipmentPresenceStatus;
  expectedInboundShipmentsStatus: ShipmentPresenceStatus;
  expectedOutboundShipmentsStatus: ShipmentPresenceStatus;
  expectedLineHaulShipmentStatus: ShipmentPresenceStatus;
  expectedOrderChargesShipmentStatus: ShipmentPresenceStatus;
}) => {
  const inboundStopMatchesExpected = isEqual(
    currentInboundShipmentStatus,
    expectedInboundShipmentsStatus,
  );
  const outboundStopMatchesExpected = isEqual(
    currentOutboundShipmentStatus,
    expectedOutboundShipmentsStatus,
  );
  const lineHaulShipmentMatchesExpected = isEqual(
    currentLineHaulShipmentStatus,
    expectedLineHaulShipmentStatus,
  );
  const orderChargesShipmentMatchesExpected = isEqual(
    currentOrderChargesShipmentStatus,
    expectedOrderChargesShipmentStatus,
  );
  if (!inboundStopMatchesExpected) {
    // eslint-disable-next-line no-console
    console.log(
      `inbound stop does not match expected: current=${JSON.stringify(
        currentInboundShipmentStatus,
      )}, expected=${JSON.stringify(expectedInboundShipmentsStatus)}`,
    );
  }
  if (!outboundStopMatchesExpected) {
    // eslint-disable-next-line no-console
    console.log(
      `outbound stop does not match expected: current=${JSON.stringify(
        currentOutboundShipmentStatus,
      )}, expected=${JSON.stringify(expectedOutboundShipmentsStatus)}`,
    );
  }
  if (!lineHaulShipmentMatchesExpected) {
    // eslint-disable-next-line no-console
    console.log(
      `line haul shipment does not match expected: current=${JSON.stringify(
        currentLineHaulShipmentStatus,
      )}, expected=${JSON.stringify(expectedLineHaulShipmentStatus)}`,
    );
  }
  if (!orderChargesShipmentMatchesExpected) {
    // eslint-disable-next-line no-console
    console.log(
      `order charges shipment does not match expected: current=${JSON.stringify(
        currentOrderChargesShipmentStatus,
      )}, expected=${JSON.stringify(expectedOrderChargesShipmentStatus)}`,
    );
  }
  if (
    inboundStopMatchesExpected &&
    outboundStopMatchesExpected &&
    lineHaulShipmentMatchesExpected &&
    orderChargesShipmentMatchesExpected
  ) {
    // eslint-disable-next-line no-console
    console.log('all shipments match expected');
  }
};

export const getExpectedOrderComponentStatus = ({
  fulfillmentType,
  inboundStop,
  outboundStop,
  lineHaulShipment,
  orderChargesShipment,
  isUsingLineHaul,
  debugLog,
}: {
  fulfillmentType: FulfillmentType | null | undefined;
  inboundStop: StopValues | null | undefined;
  outboundStop: StopValues | null | undefined;
  lineHaulShipment: LineHaulShipmentValues | null | undefined;
  orderChargesShipment: OrderChargesShipmentValues | null | undefined;
  isUsingLineHaul: boolean;
  debugLog?: boolean;
}) => {
  const inboundStopType = inboundStop?.stopType;
  const outboundStopType = outboundStop?.stopType;

  const currentInboundShipmentStatus = getCurrentShipmentStatus(inboundStop);
  const currentOutboundShipmentStatus = getCurrentShipmentStatus(outboundStop);
  const currentLineHaulShipmentStatus =
    getCurrentShipmentStatus(lineHaulShipment);
  const currentOrderChargesShipmentStatus =
    getCurrentShipmentStatus(orderChargesShipment);

  const expectedInboundShipmentsStatus = getExpectedRegularShipmentsStatus({
    stopType: inboundStopType,
    fulfillmentType,
  });

  const expectedOutboundShipmentsStatus = getExpectedRegularShipmentsStatus({
    stopType: outboundStopType,
    fulfillmentType,
  });

  const expectedOrderChargesShipmentStatus =
    getExpectedOrderChargesShipmentStatus({
      fulfillmentType,
      inboundStopType,
      outboundStopType,
    });
  const expectedLineHaulShipmentStatus =
    getExpectedLineHaulShipmentStatus(isUsingLineHaul);

  if (ENABLE_DEBUG_LOG && debugLog === true) {
    logDifferences({
      currentInboundShipmentStatus,
      currentOutboundShipmentStatus,
      currentLineHaulShipmentStatus,
      currentOrderChargesShipmentStatus,
      expectedInboundShipmentsStatus,
      expectedOutboundShipmentsStatus,
      expectedLineHaulShipmentStatus,
      expectedOrderChargesShipmentStatus,
    });
  }

  return {
    currentInboundShipmentStatus,
    currentOutboundShipmentStatus,
    currentLineHaulShipmentStatus,
    currentOrderChargesShipmentStatus,
    expectedInboundShipmentsStatus,
    expectedOutboundShipmentsStatus,
    expectedLineHaulShipmentStatus,
    expectedOrderChargesShipmentStatus,
  };
};

export const contextToNamePrefix = (
  context: OrderShipmentContext,
): `stops.${number}` | 'orderChargesShipment' => {
  if (context.shipmentType === ShipmentType.Regular) {
    return `stops.${context.stopIdx}`;
  }
  return 'orderChargesShipment';
};

interface ShouldShowFulfillmentWarningParams {
  orderValues: OrderFormValues;
  stopValues: StopValues[];
}

export const shouldShowFulfillmentWarning = ({
  orderValues,
  stopValues,
}: ShouldShowFulfillmentWarningParams): boolean => {
  if (orderValues.fulfillmentType !== FulfillmentType.Dedicated) {
    return false;
  }

  if (isNil(stopValues[0]?.serviceDate) && isNil(stopValues[1]?.serviceDate)) {
    return false;
  }

  return !dayjs(stopValues[0]?.serviceDate).isSame(
    stopValues[1]?.serviceDate,
    'date',
  );
};

import { Box, Button, Chip, Stack, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { safeAdd } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import { ORDER_PAGE_MARK_AS_MODAL_BUTTON_TEST_ID } from '../../../../../../../constants';
import DateField from '../../../../../../common/components/date-field';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import { useOrderWithPaperworkQuery } from '../../../../../../generated/graphql';
import PalletRouterLink from '../../../../../../pallet-ui/links/router-link/pallet-router-link';
import { getOrderDetailedStatusCopy } from '../../../utils';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { OrderFormValues, PackageValues } from '../../forms/types';
import { getCanMarkReceivedAtOrigin } from '../../forms/utils';
import { OnSubmitParams } from '../../types';
import LineHaulLaneProgress from '../right-sidebar/components/line-haul-lane-progress';
import CashOnDelivery from './cash-on-delivery';
import MarkAsMenu from './mark-as-menu';
import CannotCompleteOrderModal from './modals/cannot-complete-order-modal';
import PieceStatusesTooltip from './piece-statuses-tooltip';
import Stops from './stops';

type OverviewProps = {
  isEditMode: boolean;
  onSubmit: (params: OnSubmitParams) => Promise<boolean>;
};

const Overview = ({ isEditMode, onSubmit }: OverviewProps) => {
  const theme = useTheme();

  const [cannotCompleteOrderModalOpen, setCannotCompleteOrderModalOpen] =
    useState(false);
  const [cannotCompleteOrderModalMessage, setCannotCompleteOrderModalMessage] =
    useState<string | undefined>();

  const { control, setValue } = useFormContext<OrderFormValues>();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const uuid = useWatch({ control, name: 'uuid' });
  const isCollectOnDelivery = useWatch({
    control,
    name: 'isCollectOnDelivery',
  });
  const holdReasonName = useWatch({ control, name: 'holdReasonName' });
  const receivedDate = useWatch({ control, name: 'receivedDate' });
  const receivedAtOriginDate = useWatch({
    control,
    name: 'receivedAtOriginDate',
  });
  const refusedBy = useWatch({ control, name: 'refusedBy' });
  const refusedDate = useWatch({ control, name: 'refusedDate' });
  const piecesMarkedOnHand = useWatch({ control, name: 'pieceCount' });
  const packages: PackageValues[] =
    useWatch({ control, name: 'packages' }) ?? [];
  const pickedDate = useWatch({ control, name: 'pickedDate' });
  const loadedDate = useWatch({ control, name: 'loadedDate' });
  const stops = useWatch({ control, name: 'stops' });
  const lineHaulShipment = useWatch({ control, name: 'lineHaulShipment' });
  const orderChargesShipment = useWatch({
    control,
    name: 'orderChargesShipment',
  });
  const stopsOrderForm = useWatch({ control, name: 'stops' });
  const markAsButtonRef = useRef<HTMLButtonElement>(null);
  const [showMarkAsMenu, setShowMarkAsMenu] = useState(false);

  const ffPieceStatusesOnOrderForm = useFeatureFlag(
    FeatureFlag.FF_PIECE_STATUSES_ON_ORDER_FORM,
  );

  const totalPieces = packages.reduce(
    (prev, curr) => safeAdd(prev, curr.quantity ?? 0),
    0,
  );
  // Use a Set to keep track of unique UUIDs
  const uniqueUUIDs = new Set();

  const canMarkReceivedAtOrigin = getCanMarkReceivedAtOrigin({
    stops: stops ?? [],
  });

  const invoiceableStops = [
    ...(stops ?? []),
    ...filterNotNil([lineHaulShipment, orderChargesShipment]),
  ];
  // Filter the array to keep only objects with unique UUIDs
  const filteredInvoicedStops = useMemo(() => {
    return (
      invoiceableStops
        .filter((stop) => {
          if (!uniqueUUIDs.has(stop.invoiceUuid)) {
            uniqueUUIDs.add(stop.invoiceUuid);
            return true;
          }
          return false;
        })
        ?.filter(
          (stop) => !isNil(stop.invoiceUuid) && !isNil(stop.invoiceName),
        ) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceableStops.length]);

  const {
    data: paperworkCompleteData,
    loading: paperworkCompleteQueryLoading,
  } = useOrderWithPaperworkQuery(
    isNilOrEmptyString(uuid) ? { skip: true } : { variables: { uuid } },
  );
  const paperworkCompleteLoading = isNil(uuid) || paperworkCompleteQueryLoading;
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;

  const { disabledIfInvoicePosted } = useOrderFormEditAccess();

  return (
    <>
      {showMarkAsMenu && (
        <MarkAsMenu
          buttonRef={markAsButtonRef.current}
          isEditMode={isEditMode}
          setShowContextMenu={setShowMarkAsMenu}
          showContextMenu={showMarkAsMenu}
          onSubmit={onSubmit}
          setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
          setCannotCompleteOrderModalMessage={
            setCannotCompleteOrderModalMessage
          }
        />
      )}
      <Stack
        direction="column"
        mb={1}
        alignItems="start"
        justifyContent="start"
      >
        <Typography fontSize="14px" sx={{ color: theme.palette.grey['600'] }}>
          Order status
        </Typography>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography fontSize={24} fontWeight={500}>
            {getOrderDetailedStatusCopy({
              detailedStatus,
              holdReasonName,
              refusedBy,
            })}
          </Typography>
          {ffPieceStatusesOnOrderForm && (
            <Stack direction="row" gap={1} alignItems="center">
              <Typography>
                {piecesMarkedOnHand ?? 0} of {totalPieces}{' '}
                {pluralize('pkg', piecesMarkedOnHand ?? undefined)} on hand
              </Typography>
              <PieceStatusesTooltip />
            </Stack>
          )}
        </Stack>
        <Button
          size="small"
          onClick={() => {
            setShowMarkAsMenu(!showMarkAsMenu);
          }}
          ref={markAsButtonRef}
          disabled={disabledIfInvoicePosted}
          sx={{
            justifyContent: 'flex-start',
            paddingLeft: 0,
            paddingTop: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          data-testid={ORDER_PAGE_MARK_AS_MODAL_BUTTON_TEST_ID}
        >
          Edit
        </Button>
      </Stack>
      <Stack direction="column" gap={1} mb={2}>
        {!isNil(refusedBy) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Refused by {refusedBy}</Typography>
            <DateField
              date={refusedDate ?? null}
              setDate={async (date) => {
                if (!isNil(date)) {
                  setValue('refusedDate', date);
                }
              }}
              editable={!disabledIfInvoicePosted}
            />
          </Stack>
        )}
        {!isNil(receivedAtOriginDate) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Marked received at origin</Typography>
            <DateField
              date={receivedAtOriginDate}
              setDate={async (date) => {
                setValue('receivedAtOriginDate', date);
              }}
              editable={!disabledIfInvoicePosted}
            />
          </Stack>
        )}
        {canMarkReceivedAtOrigin && isNil(receivedAtOriginDate) && (
          <Typography>Not received at origin</Typography>
        )}
        {!isNil(receivedDate) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Marked on hand</Typography>
            <DateField
              date={receivedDate}
              setDate={async (date) => {
                setValue('receivedDate', date);
              }}
              editable={!disabledIfInvoicePosted}
            />
          </Stack>
        )}
        {!ffPieceStatusesOnOrderForm && !isNil(piecesMarkedOnHand) && (
          <Typography>
            {piecesMarkedOnHand} of {totalPieces} pieces on hand
          </Typography>
        )}
        {!ffPieceStatusesOnOrderForm && isNil(receivedDate) && (
          <Typography>Not on hand</Typography>
        )}
        {isEditMode && !isNil(pickedDate) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Picked</Typography>
            <DateField
              date={pickedDate}
              setDate={async (date) => {
                setValue('pickedDate', date);
              }}
              editable={!disabledIfInvoicePosted}
            />
          </Stack>
        )}
        {isEditMode && !isNil(loadedDate) && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Loaded</Typography>
            <DateField
              date={loadedDate}
              setDate={async (_) => {}}
              editable={false}
            />
          </Stack>
        )}
      </Stack>
      <Stack gap={2}>
        {isEditMode && !paperworkCompleteLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {paperworkComplete ? (
              <Chip label="Paperwork completed" />
            ) : (
              stopsOrderForm?.some((s) => !isNil(s.completedAt)) === true && (
                <Chip label="Paperwork not completed" />
              )
            )}
          </Box>
        )}
        {isEditMode && filteredInvoicedStops.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '16px' }}>
              Invoice
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {filteredInvoicedStops.map((stop) => (
                <PalletRouterLink
                  key={stop.uuid}
                  to={`/accounting?invoiceUuid=${stop.invoiceUuid}&invoiceName=${stop.invoiceName}`}
                >
                  <Typography>{stop.invoiceName}</Typography>
                </PalletRouterLink>
              ))}
            </Box>
          </Box>
        )}
        {isEditMode && (
          <Stops
            isEditMode
            onSubmit={onSubmit}
            setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
            setCannotCompleteOrderModalMessage={
              setCannotCompleteOrderModalMessage
            }
          />
        )}
        {isCollectOnDelivery === true && <CashOnDelivery />}
        <LineHaulLaneProgress isEditMode={isEditMode} />
      </Stack>
      <CannotCompleteOrderModal
        open={cannotCompleteOrderModalOpen}
        setOpen={setCannotCompleteOrderModalOpen}
        message={cannotCompleteOrderModalMessage}
        setMessage={setCannotCompleteOrderModalMessage}
      />
    </>
  );
};

export default Overview;

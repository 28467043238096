import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import useBillingReviewStore from '../../billing-review-store';
import PostFinalizedModalV2 from './post-finalized-modal-v2';

const SelectAndSendOrders = () => {
  const [showSendFinalizedModal, setShowSendFinalizedModal] = useState(false);
  const [
    selectedOrdersToSendShipments,
    clearOrdersToSendShipments,
    isSelectingOrdersToSend,
    setIsSelectingOrdersToSendInReviewModal,
    setOpenedOutstandingOrderUuid,
    outstandingOrdersInPage,
    setOutstandingOrdersInPage,
    removeSearchedOrdersByUuids,
  ] = useBillingReviewStore(
    (state) => [
      state.selectedOrdersToSendShipments,
      state.clearOrdersToSendShipments,
      state.isSelectingOrdersToSendInReviewModal,
      state.setIsSelectingOrdersToSendInReviewModal,
      state.setOpenedOutstandingOrderUuid,
      state.outstandingOrdersInPage,
      state.setOutstandingOrdersInPage,
      state.removeSearchedOrdersByUuids,
    ],
    shallow,
  );

  const onSubmitFinalizedOrders = () => {
    setOpenedOutstandingOrderUuid(undefined);
    const postableOrderUuids = selectedOrdersToSendShipments.map(
      (order) => order.uuid,
    );
    const newOutstandingOrdersInPage = outstandingOrdersInPage.filter(
      (o) => !postableOrderUuids.includes(o.uuid),
    );
    setOutstandingOrdersInPage(newOutstandingOrdersInPage);
    removeSearchedOrdersByUuids(postableOrderUuids);
    clearOrdersToSendShipments();
    setIsSelectingOrdersToSendInReviewModal(false);
  };

  const numberOfOrdersToSend = uniqBy(
    selectedOrdersToSendShipments,
    (order) => order.uuid,
  ).length;

  return (
    <>
      {isSelectingOrdersToSend ? (
        <Stack direction="row" alignItems="center">
          <Button
            color="info"
            disabled={numberOfOrdersToSend === 0}
            onClick={() => {
              setShowSendFinalizedModal(true);
            }}
          >
            Send orders ({numberOfOrdersToSend})
          </Button>
          <Tooltip title="Cancel">
            <IconButton
              onClick={() => {
                setIsSelectingOrdersToSendInReviewModal(false);
                clearOrdersToSendShipments();
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Orders must be marked as ready to invoice in order to be selected">
          <Button
            color="info"
            onClick={() => {
              setIsSelectingOrdersToSendInReviewModal(true);
            }}
          >
            Select orders to send
          </Button>
        </Tooltip>
      )}
      {isSelectingOrdersToSend && !isNil(selectedOrdersToSendShipments) && (
        <PostFinalizedModalV2
          isOpen={showSendFinalizedModal}
          setIsOpen={setShowSendFinalizedModal}
          selectedOrderUuids={selectedOrdersToSendShipments.map(
            (order) => order.uuid,
          )}
          onClose={() => {}}
          onSubmit={onSubmitFinalizedOrders}
        />
      )}
    </>
  );
};

export default React.memo(SelectAndSendOrders);

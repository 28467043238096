import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  InvoiceEmailSubjectValidationResponse,
  validateInvoiceEmailSubject,
} from 'shared/email';
import { safeMultiply } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import DocumentTypeSelector from '../../../common/components/document-type-selector';
import useDocuments from '../../../common/react-hooks/use-documents';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  BillingCycleDuration,
  ContactsDocument,
  DocumentType,
  InvoiceTransmissionMethod,
  InvoiceType,
  PermissionResource,
  TimePeriod,
  useContactQuery,
  useInvoiceTermsQuery,
  useUpdateCustomerContactMutation,
} from '../../../generated/graphql';
import InvoiceContactEmails from './invoice-contact-emails';

const ContactInvoicesSettings = ({ contactUuid }: { contactUuid: string }) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const [creditHoldLimit, setCreditHoldLimit] = useState<number | undefined>(
    undefined,
  );
  const [showErrorSnackbar, setShowErrorSnackbar] = useState<boolean>(false);
  const [enableCreditHoldChecked, setEnableCreditHoldChecked] =
    useState<boolean>(false);
  const [maxDaysOutstanding, setMaxDaysOutstanding] = useState<
    number | undefined
  >(undefined);
  const [isEditingCreditLimit, setIsEditingCreditLimit] =
    useState<boolean>(false);
  const [isEditingMaxDaysOutstanding, setIsEditingMaxDaysOutstanding] =
    useState<boolean>(false);
  const [numberOfOrdersPerInvoice, setNumberOfOrdersPerInvoice] = useState<
    number | undefined
  >();
  const [defaultInvoiceTermsUuid, setDefaultInvoiceTermsUuid] = useState<
    string | undefined
  >();
  const [billingCycleDuration, setBillingCycleDuration] = useState<
    BillingCycleDuration | undefined
  >();
  const [invoiceTimePeriodSplit, setInvoiceTimePeriodSplit] = useState<
    TimePeriod | undefined
  >();
  const [invoiceEmailSubject, setInvoiceEmailSubject] = useState<string>();
  const [invoiceNotes, setInvoiceNotes] = useState<string>();
  const [
    defaultInvoiceTransmissionMethod,
    setDefaultInvoiceTransmissionMethod,
  ] = useState<InvoiceTransmissionMethod>();
  const [defaultInvoiceType, setDefaultInvoiceType] = useState<InvoiceType>();
  const [defaultInvoiceDocumentsRequired, setDefaultInvoiceDocumentsRequired] =
    useState<DocumentType[]>([]);
  const [invoiceDocumentAttachments, setInvoiceDocumentAttachments] = useState<
    DocumentType[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateContact] = useUpdateCustomerContactMutation({
    refetchQueries: [ContactsDocument],
  });
  const { data: contactData } = useContactQuery({
    variables: {
      uuid: contactUuid,
    },
  });
  const { data: companyInvoiceTermsData } = useInvoiceTermsQuery();

  const {
    documentTypesForSelection,
    getDocumentTypeCopy,
    loading: documentsLoading,
  } = useDocuments();

  const disableActions = documentsLoading || !canWriteContacts;

  useEffect(() => {
    if (isSaving) {
      // the illusion of saving lol
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  }, [isSaving]);

  useEffect(() => {
    const contact = contactData?.contact;
    if (
      !isNil(contact) &&
      contact.__typename === 'CustomerContactEntity' &&
      !documentsLoading
    ) {
      setCreditHoldLimit(
        !isNil(contact.creditHoldLimitInCents)
          ? currency(contact.creditHoldLimitInCents, { fromCents: true }).value
          : undefined,
      );
      setEnableCreditHoldChecked(contact.enableCreditHold === true);
      setMaxDaysOutstanding(contact.maxDaysOutstanding ?? undefined);
      setNumberOfOrdersPerInvoice(
        contact.numberOfOrdersPerInvoice ?? undefined,
      );
      setInvoiceTimePeriodSplit(contact.invoiceTimePeriodSplit ?? undefined);
      setDefaultInvoiceTermsUuid(
        contact.defaultInvoiceTerms?.uuid ?? undefined,
      );
      setBillingCycleDuration(contact.billingCycleDuration);
      setDefaultInvoiceTransmissionMethod(
        contact.defaultInvoiceTransmissionMethod,
      );
      setDefaultInvoiceType(contact.defaultInvoiceType);
      setDefaultInvoiceDocumentsRequired(
        contact.defaultInvoiceDocumentsRequired,
      );
      setInvoiceDocumentAttachments(contact.defaultInvoiceDownloadDocuments);
      setInvoiceNotes(contact.invoiceNotes ?? undefined);
      setInvoiceEmailSubject(contact.defaultInvoiceEmailSubject ?? undefined);
    }
  }, [contactData, documentsLoading, documentTypesForSelection]);

  const validationResponse =
    useMemo<InvoiceEmailSubjectValidationResponse>(() => {
      return validateInvoiceEmailSubject(invoiceEmailSubject ?? '');
    }, [invoiceEmailSubject]);

  const update = async (newEnableCreditHoldChecked?: boolean) => {
    setIsSaving(true);
    try {
      await updateContact({
        variables: {
          input: {
            customerContactUpdateInput: {
              uuid: contactUuid,
              numberOfOrdersPerInvoice: numberOfOrdersPerInvoice ?? null,
              invoiceTimePeriodSplit: invoiceTimePeriodSplit ?? null,
              defaultInvoiceTermUuid: defaultInvoiceTermsUuid ?? null,
              billingCycleDuration: billingCycleDuration ?? undefined,
              defaultInvoiceTransmissionMethod:
                defaultInvoiceTransmissionMethod ?? undefined,
              defaultInvoiceType: defaultInvoiceType ?? undefined,
              defaultInvoiceDocumentsRequired,
              defaultInvoiceDownloadDocuments: invoiceDocumentAttachments,
              enableCreditHold: newEnableCreditHoldChecked,
              creditHoldLimitInCents: isNil(creditHoldLimit)
                ? null
                : safeMultiply(creditHoldLimit, 100),
              maxDaysOutstanding: isNil(maxDaysOutstanding)
                ? null
                : maxDaysOutstanding,
              invoiceNotes: invoiceNotes ?? null,
              defaultInvoiceEmailSubject:
                invoiceEmailSubject === '' ? null : invoiceEmailSubject,
            },
          },
        },
      });
    } catch (e) {
      setShowErrorSnackbar(true);
      captureException(e);
    }
  };

  return (
    <Box>
      {showErrorSnackbar && (
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showErrorSnackbar}
          onClose={() => {
            setShowErrorSnackbar(false);
          }}
        >
          <Alert onClose={() => setShowErrorSnackbar(false)}>
            Error updating settings. Please reload the page and try again or
            contact support.
          </Alert>
        </Snackbar>
      )}
      <Stack direction="column" spacing={3} sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h6">Invoices</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{ fontSize: '12px', color: 'gray', float: 'right' }}
            >
              {isSaving ? 'Saving...' : 'Saved'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {contactData?.contact.__typename === 'CustomerContactEntity' && (
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Credit Hold:
                  </Typography>
                  <Checkbox
                    sx={{ p: '0px' }}
                    size="small"
                    checked={enableCreditHoldChecked}
                    onChange={(e) => {
                      setEnableCreditHoldChecked(e.target.checked);
                      update(e.target.checked);
                    }}
                    disabled={disableActions}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Credit Limit:
                  </Typography>
                  {isEditingCreditLimit ? (
                    <TextField
                      sx={{ width: '60px' }}
                      type="number"
                      value={creditHoldLimit}
                      onChange={(e) => {
                        if (isEmpty(e.target.value)) {
                          setCreditHoldLimit(undefined);
                        } else {
                          const parsedFloat = parseFloat(e.target.value);
                          if (!Number.isNaN(parsedFloat)) {
                            setCreditHoldLimit(parsedFloat);
                          }
                        }
                      }}
                      onBlur={() => {
                        update();
                        setIsEditingCreditLimit(false);
                      }}
                      variant="standard"
                      size="small"
                      disabled={disableActions}
                    />
                  ) : (
                    <>
                      <Typography>
                        {!isNil(creditHoldLimit)
                          ? currency(creditHoldLimit).format()
                          : 'N/A'}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setIsEditingCreditLimit(true);
                        }}
                        disabled={!canWriteContacts}
                      >
                        <EditIcon sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Max Days Outstanding:
                  </Typography>
                  {isEditingMaxDaysOutstanding ? (
                    <TextField
                      sx={{ width: '60px' }}
                      type="number"
                      value={maxDaysOutstanding}
                      onChange={(e) => {
                        if (isEmpty(e.target.value)) {
                          setMaxDaysOutstanding(undefined);
                        } else {
                          const parsedFloat = parseFloat(e.target.value);
                          if (!Number.isNaN(parsedFloat)) {
                            setMaxDaysOutstanding(parsedFloat);
                          }
                        }
                      }}
                      onBlur={() => {
                        update();
                        setIsEditingMaxDaysOutstanding(false);
                      }}
                      variant="standard"
                      size="small"
                      disabled={disableActions}
                    />
                  ) : (
                    <>
                      <Typography>
                        {!isNil(maxDaysOutstanding)
                          ? maxDaysOutstanding
                          : 'N/A'}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setIsEditingMaxDaysOutstanding(true);
                        }}
                        disabled={disableActions}
                      >
                        <EditIcon sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Number of orders per invoice
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                inputProps={{ pattern: '[0-9]*' }}
                value={numberOfOrdersPerInvoice}
                onChange={(e) => {
                  const parsed = Number.parseFloat(e.target.value);
                  if (!Number.isNaN(parsed)) {
                    setNumberOfOrdersPerInvoice(parsed);
                  } else {
                    setNumberOfOrdersPerInvoice(undefined);
                  }
                }}
                onBlur={() => {
                  update();
                }}
                disabled={disableActions}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Invoice Time Period Split
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                size="small"
                value={invoiceTimePeriodSplit ?? 'NONE'}
                required
                onChange={(event) => {
                  if (event.target.value === 'NONE') {
                    setInvoiceTimePeriodSplit(undefined);
                  } else {
                    setInvoiceTimePeriodSplit(event.target.value as TimePeriod);
                  }
                }}
                onBlur={() => {
                  update();
                }}
                disabled={disableActions}
              >
                <MenuItem value="NONE">None</MenuItem>
                {Object.values(TimePeriod).map((timePeriod) => (
                  <MenuItem key={timePeriod} value={timePeriod}>
                    {sentenceCase(timePeriod)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Default invoice terms
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                size="small"
                value={defaultInvoiceTermsUuid ?? 'NONE'}
                required
                onChange={(event) => {
                  if (event.target.value === 'NONE') {
                    setDefaultInvoiceTermsUuid(undefined);
                  } else {
                    setDefaultInvoiceTermsUuid(
                      companyInvoiceTermsData?.invoiceTerms.find(
                        (term) => term.uuid === event.target.value,
                      )?.uuid,
                    );
                  }
                }}
                onBlur={() => {
                  update();
                }}
                disabled={disableActions}
              >
                <MenuItem value="NONE">None</MenuItem>
                {[...(companyInvoiceTermsData?.invoiceTerms ?? [])]
                  .sort((a, b) => {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    const termA = parseInt(a?.terms.slice(4), 10) || 0;
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    const termB = parseInt(b?.terms.slice(4), 10) || 0;

                    return termA - termB; // Compare numerically
                  })
                  .map((term) => (
                    <MenuItem key={term.uuid} value={term.uuid}>
                      {sentenceCase(term.terms)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Billing cycle
            </Typography>
            <FormControl sx={{ width: '100%' }}>
              <Select
                size="small"
                value={billingCycleDuration ?? 'NONE'}
                required
                onChange={(event) => {
                  if (event.target.value === 'NONE') {
                    setBillingCycleDuration(undefined);
                  } else {
                    setBillingCycleDuration(
                      event.target.value as BillingCycleDuration,
                    );
                  }
                }}
                onBlur={() => {
                  update();
                }}
                disabled={disableActions}
              >
                {Object.values(BillingCycleDuration).map((duration) => (
                  <MenuItem key={duration} value={duration}>
                    {sentenceCase(duration)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl sx={{ width: '100%' }}>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Transmission Method
              </Typography>
              <Select
                labelId="transmission-method-label"
                onChange={(e) => {
                  setDefaultInvoiceTransmissionMethod(
                    e.target.value as InvoiceTransmissionMethod,
                  );
                }}
                onBlur={() => {
                  update();
                }}
                value={defaultInvoiceTransmissionMethod ?? ''}
                required
                size="small"
                disabled={disableActions}
              >
                <MenuItem
                  id={InvoiceTransmissionMethod.None}
                  value={InvoiceTransmissionMethod.None}
                >
                  {sentenceCase(InvoiceTransmissionMethod.None)}
                </MenuItem>
                <MenuItem
                  id={InvoiceTransmissionMethod.Download}
                  value={InvoiceTransmissionMethod.Download}
                >
                  {sentenceCase(InvoiceTransmissionMethod.Download)}
                </MenuItem>
                <MenuItem
                  id={InvoiceTransmissionMethod.Email}
                  value={InvoiceTransmissionMethod.Email}
                >
                  {sentenceCase(InvoiceTransmissionMethod.Email)}
                </MenuItem>
                <MenuItem
                  id={InvoiceTransmissionMethod.EmailAndDownload}
                  value={InvoiceTransmissionMethod.EmailAndDownload}
                >
                  {sentenceCase(InvoiceTransmissionMethod.EmailAndDownload)}
                </MenuItem>
                {!isNil(contactData?.contact.stediPartnerId) && (
                  <MenuItem
                    id={InvoiceTransmissionMethod.Edi}
                    value={InvoiceTransmissionMethod.Edi}
                  >
                    {sentenceCase(InvoiceTransmissionMethod.Edi)}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl sx={{ width: '100%' }}>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Invoice Type
              </Typography>
              <Select
                labelId="invoice-type-label"
                onChange={(e) => {
                  setDefaultInvoiceType(e.target.value as InvoiceType);
                }}
                onBlur={() => {
                  update();
                }}
                value={defaultInvoiceType ?? ''}
                required
                size="small"
                sx={{ backgroundColor: 'white' }}
                disabled={disableActions}
              >
                {Object.values(InvoiceType).map((type) => (
                  <MenuItem key={type} id={type} value={type}>
                    {sentenceCase(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Required Documents
              </Typography>
              <Select
                multiple
                displayEmpty
                onChange={(e) => {
                  setDefaultInvoiceDocumentsRequired(
                    e.target.value as DocumentType[],
                  );
                }}
                onBlur={() => {
                  update();
                }}
                value={defaultInvoiceDocumentsRequired}
                required
                notched
                size="small"
                sx={{ backgroundColor: 'white' }}
                renderValue={(selected) =>
                  selected.length === 0 ? (
                    <Box>None</Box>
                  ) : (
                    <Box>
                      {selected.map((value) => (
                        <Chip key={value} label={sentenceCase(value)} />
                      ))}
                    </Box>
                  )
                }
                disabled={disableActions}
              >
                {documentTypesForSelection?.map((docType) => (
                  <MenuItem key={docType} id={docType} value={docType}>
                    {getDocumentTypeCopy({
                      documentType: docType,
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <Typography
                sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}
              >
                Documents in Invoice
              </Typography>
              <DocumentTypeSelector
                value={invoiceDocumentAttachments}
                disabled={disableActions}
                onChange={(value) => {
                  setInvoiceDocumentAttachments(value);
                }}
                onBlur={() => {
                  update();
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row">
              <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
                Invoice Email Subject
              </Typography>
              <Tooltip
                title="Default invoice email subject. Overrides company default. Can be overridden by on an individual basis when sending invoices."
                placement="right"
                arrow
              >
                <InfoIcon sx={{ width: '16px', mx: 1, color: 'gray' }} />
              </Tooltip>
            </Stack>
            <Typography variant="caption" sx={{ mb: '10px', color: 'gray' }}>
              Variables available: {'{{company}}'}, {'{{invoice}}'},{' '}
              {'{{customer}}'}
            </Typography>
            <TextField
              sx={{ mt: '10px' }}
              fullWidth
              value={invoiceEmailSubject}
              onChange={(e) => {
                setInvoiceEmailSubject(e.target.value);
              }}
              error={validationResponse.result === 'invalid'}
              helperText={
                validationResponse.result === 'invalid'
                  ? 'Invalid template format. Example: Invoice {{invoice}} from {{company}} for {{customer}}'
                  : ''
              }
              disabled={disableActions}
              onBlur={async () => {
                if (
                  validationResponse.result === 'valid' ||
                  validationResponse.result === 'empty'
                ) {
                  update();
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ mb: '10px', color: 'black', fontWeight: 'bold' }}>
              Invoice Notes
            </Typography>
            <TextField
              fullWidth
              multiline
              value={invoiceNotes}
              onChange={(e) => {
                setInvoiceNotes(e.target.value);
              }}
              onBlur={() => {
                update();
              }}
              disabled={disableActions}
              maxRows={6}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <InvoiceContactEmails contactUuid={contactUuid} />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ContactInvoicesSettings;

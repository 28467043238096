import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { FieldPath, useForm, UseFormReturn } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import { useAccessorialsQuery } from '../../../../../generated/graphql';
import { getOrderSchema, OrderFormVariant } from './order-schema';
import { OrderFormValues } from './types';
import { getIsFieldRequired, getIsFieldVisible } from './zod-utils';

type UseOrderFormOptions = {
  variant: OrderFormVariant;
  isRecurringTemplate: boolean;
};

type UseOrderFormReturn = {
  form: UseFormReturn<OrderFormValues>;
  loading: boolean;
  variant: OrderFormVariant;
  isFieldRequired: (field: FieldPath<OrderFormValues>) => boolean;
  isFieldVisible: (field: FieldPath<OrderFormValues>) => boolean;
};

const useOrderForm = ({
  variant,
  isRecurringTemplate,
}: UseOrderFormOptions): UseOrderFormReturn => {
  const { companyData, companyConfiguration, loading: meLoading } = useMe();
  const disallowZeroDollarCharges = useFeatureFlag(
    FeatureFlag.FF_DISALLOW_ZERO_DOLLAR_CHARGES,
  );
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );
  const serviceLevelOptional = companyConfiguration?.serviceLevelOptional;
  const fulfillmentTypeEnabled = companyConfiguration?.fulfillmentTypeEnabled;
  // useOrderFormAccessorials can only be used within a form context :(
  const { data: accessorialsData, loading: accessorialsLoading } =
    useAccessorialsQuery({
      variables: {
        onlyShowCompanyAccessorials: true,
      },
    });
  const loading = meLoading || accessorialsLoading;
  const schema = useMemo(
    () =>
      getOrderSchema(variant, {
        disallowZeroDollarCharges,
        accessorials: accessorialsData?.accessorials ?? [],
        ffRecoveryTransferAddressOnly,
        requireServiceLevel: serviceLevelOptional !== true,
        requireFulfillmentType: fulfillmentTypeEnabled === true,
        isRecurringTemplate,
        orderFormFields: companyData?.orderFormFields,
      }),
    [
      variant,
      disallowZeroDollarCharges,
      accessorialsData?.accessorials,
      ffRecoveryTransferAddressOnly,
      serviceLevelOptional,
      fulfillmentTypeEnabled,
      isRecurringTemplate,
      companyData?.orderFormFields,
    ],
  );

  const form: UseFormReturn<OrderFormValues> = useForm({
    resolver: zodResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
  });

  return useMemo(
    () => ({
      form,
      loading,
      variant,
      isFieldRequired: getIsFieldRequired(schema),
      isFieldVisible: getIsFieldVisible(schema),
    }),
    [form, loading, variant, schema],
  );
};

export default useOrderForm;

import { Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

type MarkStopAsFailedDialogProps = {
  handleClose: () => void;
  handleMarkStopAsFailed: (notes: string) => void;
  open: boolean;
};

export const MarkStopAsAttemptedDialog = ({
  handleClose,
  handleMarkStopAsFailed,
  open,
}: MarkStopAsFailedDialogProps) => {
  const theme = useTheme();
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setNotes('');
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Warning color="warning" sx={{ mr: 1 }} />
          Mark stop as attempted
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            Please leave a description for why this stop could not be completed:
          </Typography>
          <TextField
            label="Description"
            size="small"
            sx={{
              mt: '10px',
              width: '100%',
            }}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            setLoading(true);
            await handleMarkStopAsFailed(notes);
            setLoading(false);
            handleClose();
          }}
          disabled={loading}
        >
          Mark stop as attempted
        </Button>
      </DialogActions>
    </Dialog>
  );
};

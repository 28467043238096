import { z } from 'zod';
import { QuoteFormValues } from '../../../../quotes/components/quote-form/forms/types';
import {
  addressSchema,
  addressSchemaWithAllOptionalFields,
} from './address-schema';
import { contactPersonSchema } from './contact-person-schema';
import { getCustomChargeSchema } from './custom-charge-schema';
import { documentSchema } from './document-schema';
import { freightChargeSchema } from './freight-charge-schema';
import { fuelChargeSchema } from './fuel-charge-schema';
import { lineHaulShipmentSchema } from './line-haul-shipment-schema';
import { getOrderChargesShipmentSchema } from './order-charges-shipment-schema';
import { orderCommentSchema } from './order-comment-schema';
import { getOrderSchema } from './order-schema';
import { packageSchema } from './package-schema';
import { recurringOrderFrequencySchema } from './recurring-order-frequency-schema';
import { stopDriverMapSchema } from './stop-driver-map-schema';
import { getStopSchema } from './stop-schema';

export enum PageMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum Units {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export type RecurringOrderFrequencyValues = z.infer<
  typeof recurringOrderFrequencySchema
>;

export type CustomChargeValues = z.infer<
  ReturnType<typeof getCustomChargeSchema>
>;

export type FuelChargeValues = z.infer<typeof fuelChargeSchema>;

export type FreightChargeValues = z.infer<typeof freightChargeSchema>;

export type DocumentValues = z.infer<typeof documentSchema>;

export type OrderFormValues = z.infer<ReturnType<typeof getOrderSchema>>;

export type StopValues = z.infer<ReturnType<typeof getStopSchema>>;

export type PackageValues = z.infer<typeof packageSchema>;

export type ContactPersonValues = z.infer<typeof contactPersonSchema>;

export type AddressValues = z.infer<typeof addressSchema>;

export type AddressOptionalValues = z.infer<
  typeof addressSchemaWithAllOptionalFields
>;

export type OrderCommentValues = z.infer<typeof orderCommentSchema>;

export type StopDriverMapValues = z.infer<typeof stopDriverMapSchema>;

export type LineHaulShipmentValues = z.infer<typeof lineHaulShipmentSchema>;

export type OrderChargesShipmentValues = z.infer<
  ReturnType<typeof getOrderChargesShipmentSchema>
>;
export type OrderFormFieldValues = OrderFormValues | QuoteFormValues;

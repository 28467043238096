import {
  AppBar,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { SIDEBAR_OPEN_KEY } from '../../common/local-storage/keys';
import useTerminals from '../../common/react-hooks/use-terminals';
import { chooseForegroundColor } from '../../common/utils/colors';
import { useNewTableFunctionsFeatureFlag } from '../../domains/ag-grid/use-new-table-functions-feature-flag';
import { isDevelopment } from '../../environment-variables';
import { FilterViewPage } from '../../generated/graphql';
import LeftPanelOpen from '../../icons/left-panel-open.svg?react';
import theme from '../../theme';
import NavbarTerminalSelector from './components/navbar-terminal-selector';
import { DEV_APP_BAR_COLOR } from './constants';
import { DashboardDrawerItem, DashboardPage } from './types';

const APP_BAR_HEIGHT_NEW_PX = 45;

const getAppBarColor = () => {
  return isDevelopment() ? DEV_APP_BAR_COLOR : '#ffffff';
};

const getSidebarIconColor = () => {
  return isDevelopment() ? 'white' : 'black';
};

const DASHBOARD_STYLES: { [key: string]: CSSProperties } = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  appBarTypography: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '29px',
    marginLeft: 0,
    color: '#FFFFFF',
  },
};

interface AppBarProps {
  page: DashboardPage;
  selectedDrawerItem?: DashboardDrawerItem;
}

export const AppBarHeader = ({ page, selectedDrawerItem }: AppBarProps) => {
  const { pathname } = useLocation();
  const appbarColor = getAppBarColor();
  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    page === DashboardPage.ORDERS ? FilterViewPage.Orders : undefined,
  );
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(SIDEBAR_OPEN_KEY, {
    defaultValue: true,
  });
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const shouldShowTerminalSelector =
    terminalsEnabled &&
    (page === DashboardPage.TRANSIT ||
      (page === DashboardPage.ORDERS &&
        pathname === '/orders/inbound-messages/email') ||
      (ffEnableNewTableFunctions &&
        page === DashboardPage.ORDERS &&
        pathname === '/orders'));

  return (
    <AppBar
      component="nav"
      position="fixed"
      elevation={0}
      sx={{
        ...DASHBOARD_STYLES.appBar,
        backgroundColor: appbarColor,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        height: APP_BAR_HEIGHT_NEW_PX,
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        {!sidebarOpen && (
          <Tooltip title="Open sidebar">
            <IconButton
              size="small"
              onClick={() => {
                setSidebarOpen(true);
              }}
              sx={{
                marginRight: 1,
              }}
            >
              <LeftPanelOpen fill={getSidebarIconColor()} />
            </IconButton>
          </Tooltip>
        )}
        <Typography
          component="div"
          variant="h6"
          sx={{
            color: chooseForegroundColor(appbarColor),
          }}
        >
          {selectedDrawerItem?.tabs.find((tab) => pathname === tab.url)?.name}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          marginLeft="auto"
          alignItems="center"
        >
          {shouldShowTerminalSelector && (
            <NavbarTerminalSelector
              color={chooseForegroundColor(appbarColor)}
            />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

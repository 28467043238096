import { exhaustive } from 'shared/switch';
import { StopType as _StopType } from '../../../../../generated/graphql';

export enum StopMethod {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}

export enum StopType {
  Pickup = 'Pickup',
  Delivery = 'Delivery',
  Recovery = 'Recovery',
  Transfer = 'Transfer',
  PartnerCarrierDropoff = 'Partner carrier dropoff',
  PartnerCarrierPickup = 'Partner carrier pickup',
  None = 'None',
}

const inboundStopTypeOptions = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
  StopType.None,
];

const outboundStopTypeOptions = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export const stopTypeOptions = (method: StopMethod) => {
  return method === StopMethod.Inbound
    ? inboundStopTypeOptions
    : outboundStopTypeOptions;
};

export const convertOrderFormStopTypeToStopType = (
  stopType: StopType,
): _StopType | null => {
  switch (stopType) {
    case StopType.Pickup:
    case StopType.PartnerCarrierDropoff:
      return _StopType.Pickup;
    case StopType.Delivery:
    case StopType.PartnerCarrierPickup:
      return _StopType.Delivery;
    case StopType.Recovery:
      return _StopType.Recovery;
    case StopType.Transfer:
      return _StopType.Transfer;
    case StopType.None:
      return null;
    default:
      return exhaustive(stopType);
  }
};

// Used only for setting default stop type via service level
export const convertServiceStopTypeToOrderFormStopType = (
  stopType: _StopType,
) => {
  switch (stopType) {
    case _StopType.Pickup:
      return StopType.Pickup;
    case _StopType.PartnerCarrierDropoff:
      return StopType.PartnerCarrierDropoff;
    case _StopType.Delivery:
      return StopType.Delivery;
    case _StopType.PartnerCarrierPickup:
      return StopType.PartnerCarrierPickup;
    case _StopType.Recovery:
      return StopType.Recovery;
    case _StopType.Transfer:
      return StopType.Transfer;
    case _StopType.None:
      return StopType.None;
    default:
      return exhaustive(stopType);
  }
};

const INBOUND_STOP_TYPES = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
];

const OUTBOUND_STOP_TYPES = Object.values(StopType).filter(
  (stopType) => !INBOUND_STOP_TYPES.includes(stopType),
);

const PARTNER_CARRIER_STOP_TYPES = [
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
];

const REGULAR_STOP_TYPES = Object.values(StopType).filter(
  (stopType) => !PARTNER_CARRIER_STOP_TYPES.includes(stopType),
);

export function isInboundStop(stopType: StopType) {
  return INBOUND_STOP_TYPES.includes(stopType);
}

export function isOutboundStop(stopType: StopType) {
  return OUTBOUND_STOP_TYPES.includes(stopType);
}

export function isPartnerCarrierStop(stopType: StopType) {
  return PARTNER_CARRIER_STOP_TYPES.includes(stopType);
}

export function isRegularStop(stopType: StopType) {
  return REGULAR_STOP_TYPES.includes(stopType);
}

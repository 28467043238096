import { Scalar } from 'shared/units/scalar';
import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import { FreightBillingMethod } from '../../../../../generated/graphql';
import { fuelChargeSchema } from './fuel-charge-schema';

export const freightChargeSchema = z.object({
  uuid: z.string().uuid(),
  billingMethod: z.nativeEnum(FreightBillingMethod),
  // This can represent currency or a count. When currency, this represents a
  // dollar amount
  quantity: z.number().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  rate: z.number().nullable(),
  discountRate: z.custom<Scalar>().nullish(),
  totalCharge: z.number(),
  tariffUuid: z.string().uuid().nullable(),
  fuelCharge: fuelChargeSchema,
  description: z.string().nullable(),
  authoCode: z.string().nullable(),
  settlementPercentageRate: z.number().nullable(),
  settlementFlatRate: z.number().nullable(),
  settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
  deductionTotal: z.number(),
  errorMessage: z.string().nullable(),
});

// eslint-disable-next-line no-restricted-imports
import { Button, ButtonGroup, ButtonGroupProps } from '@mui/material';

type Option<TValue> = { value: TValue; label: string };

type NativeButtonGroupProps = Pick<ButtonGroupProps, 'size' | 'aria-label'>;

type PalletButtonGroupProps<TValue> = NativeButtonGroupProps & {
  options: Option<TValue>[];
  value: TValue;
  onChange: (value: TValue) => void;
};

const PalletButtonGroup = <TValue extends string | number>({
  options,
  value,
  onChange,
  ...restProps
}: PalletButtonGroupProps<TValue>) => {
  return (
    <ButtonGroup disableElevation {...restProps}>
      {options.map((option) => (
        <Button
          key={option.value}
          variant={value === option.value ? 'contained' : 'outlined'}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default PalletButtonGroup;

import { TextField, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isValidPartialOrCompleteNumberInput } from '../../../../../../../../../utils';
import { FuelBillingMethod } from '../../../../../../../../generated/graphql';
import { OrderFormFieldValues } from '../../../../forms/types';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';

export type FuelChargeRateInputProps = {
  billingMethod: FuelBillingMethod | undefined;
  surchargeRateInput: string;
  setSurchargeRateInput: Dispatch<SetStateAction<string>>;
  flatRateInput: string;
  setFlatRateInput: Dispatch<SetStateAction<string>>;
  disabled: boolean;
  setSurchargeRateInForm: (rate: number) => void;
  setFlatRateDollarsInForm: (rate: number) => void;
  fuelChargeRateInputTestId: string;
};

const FuelChargeRateInput = ({
  billingMethod,
  surchargeRateInput,
  setSurchargeRateInput,
  flatRateInput,
  setFlatRateInput,
  disabled,
  setSurchargeRateInForm,
  setFlatRateDollarsInForm,
  fuelChargeRateInputTestId,
}: FuelChargeRateInputProps) => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });

  const { shouldRateOrder } = useShouldRateOrder({
    detailedStatus,
  });

  if (billingMethod === FuelBillingMethod.None) {
    return <Typography>-</Typography>;
  }

  if (billingMethod === FuelBillingMethod.AutoCalculate && shouldRateOrder) {
    return (
      <Typography>
        {!isEmpty(surchargeRateInput) ? `${surchargeRateInput}%` : 'None'}
      </Typography>
    );
  }

  const valueInput =
    billingMethod === FuelBillingMethod.Percentage
      ? surchargeRateInput
      : flatRateInput;

  return (
    <TextField
      size="small"
      disabled={disabled}
      label={sentenceCase(billingMethod?.toString() ?? '')}
      value={valueInput}
      inputProps={{ 'data-testid': fuelChargeRateInputTestId }}
      onChange={(e) => {
        if (isValidPartialOrCompleteNumberInput(e.target.value)) {
          if (billingMethod === FuelBillingMethod.Percentage) {
            setSurchargeRateInput(e.target.value);
          } else {
            setFlatRateInput(e.target.value);
          }
          const parsedAmount = parseFloat(e.target.value);
          if (!Number.isNaN(parsedAmount)) {
            if (billingMethod === FuelBillingMethod.Percentage) {
              setSurchargeRateInForm(parsedAmount);
            } else {
              setFlatRateDollarsInForm(parsedAmount);
            }
          }
        }
      }}
      onBlur={() => {
        const parsedAmount = parseFloat(valueInput);
        const newValue = !Number.isNaN(parsedAmount) ? parsedAmount : null;
        if (billingMethod === FuelBillingMethod.Percentage) {
          setSurchargeRateInput(newValue?.toString() ?? '');
        } else {
          setFlatRateInput(newValue?.toString() ?? '');
        }
        if (billingMethod === FuelBillingMethod.Percentage) {
          setSurchargeRateInForm(newValue ?? 0);
        } else {
          setFlatRateDollarsInForm(newValue ?? 0);
        }
      }}
    />
  );
};

export default FuelChargeRateInput;

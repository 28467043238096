/* eslint-disable no-param-reassign */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

/**
 * @field isOrderPageRating - Useful for disabling the save button when the
 * order page is rating
 */
type OrderFormState = {
  isSavingDocScan: boolean;
  isOrderPageRating: boolean | null | undefined;
  isFreightChargeRatingIndex: number | undefined;
  justFinalizedCharges: boolean;
  unableToRateFreightChargeFormError: string | undefined;
};

type OrderFormActions = {
  setIsSavingDocScan: (saving: boolean) => void;
  setIsOrderPageRating: (loading: boolean) => void;
  setIsFreightChargeRatingIndex: (index: number | undefined) => void;
  setUnableToRateFreightChargeFormError: (error: string | undefined) => void;
  setJustFinalizedCharges: (justFinalized: boolean) => void;
};

const useOrderFormStore = create(
  immer<OrderFormState & OrderFormActions>((set) => ({
    isSavingDocScan: false,
    isOrderPageRating: undefined,
    isFreightChargeRatingIndex: undefined,
    justFinalizedCharges: false,
    unableToRateFreightChargeFormError: undefined,
    setIsSavingDocScan: (isSaving: boolean) => {
      set((state) => {
        state.isSavingDocScan = isSaving;
      });
    },
    setIsOrderPageRating: (loading: boolean) => {
      set((state) => {
        state.isOrderPageRating = loading;
      });
    },
    setIsFreightChargeRatingIndex: (index: number | undefined) => {
      set((state) => {
        state.isFreightChargeRatingIndex = index;
      });
    },

    setUnableToRateFreightChargeFormError: (error: string | undefined) => {
      set((state) => {
        state.unableToRateFreightChargeFormError = error;
      });
    },
    setJustFinalizedCharges: (justFinalized: boolean) => {
      set((state) => {
        state.justFinalizedCharges = justFinalized;
      });
    },
  })),
);

export default useOrderFormStore;

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { safeAdd } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { calculateTotalVolume, calculateTotalWeight } from 'shared/weight';
import { shallow } from 'zustand/shallow';
import {
  FREIGHT_CHARGE_NEW_TARIFF_AUTO_APPLIED_MESSAGE_TEST_ID,
  FREIGHT_CHARGE_TOTAL_TEST_ID,
} from '../../../../../../../../constants';
import {
  getOrderChargesFreightChargeTestIds,
  getStopFreightChargeTestIds,
} from '../../../../../../../../utils';
import {
  convertToInches,
  isNilOrEmptyString,
} from '../../../../../../../common/utils/utils';
import {
  FreightBillingMethod,
  OrderStatus,
  ShipmentType,
  TariffFragment,
  TariffType,
  TariffZoneType,
  useTariffLazyQuery,
} from '../../../../../../../generated/graphql';
import useOrderFormStore from '../../../../../order-form-store';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { OrderFormFieldValues } from '../../../forms/types';
import { OrderShipmentContext } from '../../../types';
import { contextToNamePrefix } from '../../../utils';
import { VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS } from '../constants';
import {
  buildNewTariffAppliedMessage,
  formatTariffRateString,
  getTariffGroupNameCopy,
} from '../utils';
import AuthoCodeComponent, {
  MT_IF_HAS_AUTHO_CODE_STYLES,
} from './autho-code-component';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from './description-component';
import MilesComponent from './miles-component';

const HIDE_FROM_BILLING = 'HIDE_FROM_BILLING';

export const QuantityField = ({
  billingMethod,
  tariff,
  context,
  disabled,
}: {
  billingMethod: FreightBillingMethod | undefined;
  tariff: TariffFragment | undefined;
  context:
    | {
        shipmentType: ShipmentType.Regular;
        stopIdx: number;
      }
    | {
        shipmentType: ShipmentType.OrderCharges;
      };
  disabled: boolean;
}) => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const useKilograms = useWatch({ control, name: 'useKilograms' });
  const useCentimeters = useWatch({ control, name: 'useCentimeters' });
  const packages = useWatch({ control, name: 'packages' });
  const dimFactor = useWatch({ control, name: 'dimFactor' });
  const totalWeight = calculateTotalWeight(
    useCentimeters !== true
      ? (packages ?? [])
      : (packages ?? []).map((pkg) => {
          return {
            ...pkg,
            length: convertToInches(pkg.length),
            width: convertToInches(pkg.width),
            height: convertToInches(pkg.height),
          };
        }),
    dimFactor ?? undefined,
    tariff?.useActualWeight ?? false,
  );
  const totalPieces = packages?.reduce(
    (prev, curr) => safeAdd(prev, curr.quantity ?? 0),
    0,
  );
  const totalVolume = calculateTotalVolume({
    packages:
      useCentimeters !== true
        ? (packages ?? [])
        : (packages ?? []).map((pkg) => {
            return {
              ...pkg,
              length: convertToInches(pkg.length),
              width: convertToInches(pkg.width),
              height: convertToInches(pkg.height),
            };
          }),
  });

  switch (billingMethod) {
    case FreightBillingMethod.Tariff: {
      if (tariff?.tariffType === TariffType.PerHundredPounds) {
        return (
          <Typography>
            {totalWeight} {useKilograms === true ? 'kgs' : 'lbs'}
          </Typography>
        );
      }
      if (tariff?.tariffType === TariffType.PerPiece) {
        return <Typography>{totalPieces} pieces</Typography>;
      }
      if (tariff?.tariffType === TariffType.PerCubicFoot) {
        return <Typography>{totalVolume} cubic feet</Typography>;
      }

      return <Typography>1</Typography>;
    }
    case FreightBillingMethod.Weight: {
      return <Typography>{totalWeight} lbs</Typography>;
    }
    case FreightBillingMethod.FlatRate: {
      return <Typography>1</Typography>;
    }
    case FreightBillingMethod.PerMile: {
      return (
        <MilesComponent
          context={context}
          shouldSetFreightChargeQuantity
          disabled={disabled}
        />
      );
    }
    case FreightBillingMethod.PerPiece: {
      return <Typography>{totalPieces} pieces</Typography>;
    }
    case null:
    case undefined: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    default:
      return exhaustive(billingMethod);
  }
};

export const RateField = ({
  context,
  billingMethod,
  tariff,
  disabled,
  loading,
}: {
  context: OrderShipmentContext;
  billingMethod: FreightBillingMethod | undefined;
  tariff: TariffFragment | undefined;
  disabled: boolean;
  loading: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const namePrefix = contextToNamePrefix(context);
  const rate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.rate`,
  });
  const total = useWatch({
    control,
    name: `${namePrefix}.freightCharge.totalCharge`,
  });

  const tariffGroupName = tariff?.tariffGroup?.name;
  const tariffGroupScope = tariff?.tariffGroup?.tariffGroupScope;
  const tariffGroupType = tariff?.tariffGroup?.tariffGroupType;

  const tariffGroupNameCopy = getTariffGroupNameCopy({
    tariffGroupScope,
    tariffGroupName,
    tariffGroupType,
  });

  const { freightChargeRateSkeletonLoadingTestId } =
    context.shipmentType === ShipmentType.Regular
      ? getStopFreightChargeTestIds({ stopIdx: context.stopIdx })
      : getOrderChargesFreightChargeTestIds();

  const RateInput = (
    <Controller
      control={control}
      name={`${namePrefix}.freightCharge.rate`}
      render={({ field: { value } }) => (
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            size="small"
            type="number"
            value={value}
            label="Rate"
            onBlur={() => {
              if (!isNil(value)) {
                setValue(
                  `${namePrefix}.freightCharge.rate`,
                  currency(value).value,
                );
              }
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setValue(`${namePrefix}.freightCharge.rate`, null);
              } else {
                const parsedFloat = parseFloat(e.target.value);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(`${namePrefix}.freightCharge.rate`, parsedFloat);
                }
              }
            }}
          />
        </FormControl>
      )}
    />
  );

  switch (billingMethod) {
    case FreightBillingMethod.Tariff: {
      return loading === true ? (
        <Skeleton data-testid={freightChargeRateSkeletonLoadingTestId} />
      ) : (
        <>
          <Typography>
            {formatTariffRateString({ tariff, rate, total })}
          </Typography>
          {!isNilOrEmptyString(tariffGroupNameCopy) && (
            <Typography variant="caption">{tariffGroupNameCopy}</Typography>
          )}
        </>
      );
    }
    case FreightBillingMethod.Weight: {
      return RateInput;
    }
    case FreightBillingMethod.FlatRate: {
      return RateInput;
    }
    case FreightBillingMethod.PerMile: {
      return RateInput;
    }
    case FreightBillingMethod.PerPiece: {
      return RateInput;
    }
    case null:
    case undefined: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    default:
      return exhaustive(billingMethod);
  }
};

const FreightChargeRow = ({
  context,
  inBillingReview,
  hideFromBilling,
  setHideFromBilling,
}: {
  context: OrderShipmentContext;
  inBillingReview: boolean;
  hideFromBilling: boolean;
  setHideFromBilling?: (hideFromBilling: boolean) => void;
}) => {
  const namePrefix = contextToNamePrefix(context);
  const [isOrderPageRating] = useOrderFormStore(
    (state) => [state.isOrderPageRating],
    shallow,
  );

  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.has('orderUuid');

  const [getTariffByUuid, { loading: loadingTariff }] = useTariffLazyQuery();
  const [tariff, setTariff] = useState<TariffFragment | undefined>(undefined);
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const freightCharge = useWatch({
    control,
    name: `${namePrefix}.freightCharge`,
  });
  const billingMethod = useWatch({
    control,
    name: `${namePrefix}.freightCharge.billingMethod`,
  });
  const tariffUuid = useWatch({
    control,
    name: `${namePrefix}.freightCharge.tariffUuid`,
  });
  const rate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.rate`,
  });
  const discountRate = useWatch({
    control,
    name: `${namePrefix}.freightCharge.discountRate`,
  });
  const total = useWatch({
    control,
    name: `${namePrefix}.freightCharge.totalCharge`,
  });
  const authoCode = useWatch({
    control,
    name: `${namePrefix}.freightCharge.authoCode`,
  });
  const errorMessage = useWatch({
    control,
    name: `${namePrefix}.freightCharge.errorMessage`,
  });
  const status = useWatch({ control, name: 'status' });

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const savedTariffUuid = useRef(tariffUuid);
  const savedTariff = useRef(tariff);
  const savedTariffRate = useRef(rate);
  const savedTariffTotal = useRef(total);

  const fetchTariff = async () => {
    if (!isNil(tariffUuid) && !isEmpty(tariffUuid)) {
      const res = await getTariffByUuid({
        variables: {
          uuid: tariffUuid,
        },
      });
      const retrievedTariff = res.data?.tariff;
      if (tariffUuid === savedTariffUuid.current) {
        savedTariff.current = retrievedTariff;
        savedTariffRate.current = rate;
        savedTariffTotal.current = total;
      }
      setTariff(retrievedTariff);
    } else {
      setTariff(undefined);
    }
  };

  useEffect(() => {
    fetchTariff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffUuid]);

  if (isNil(freightCharge)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const freightChargeLoading = loadingTariff;

  const formatFreightChargeTotal = () => {
    if (!isEmpty(errorMessage) || freightChargeLoading) {
      return '-';
    }
    if (hideFromBilling) {
      return currency(0).format();
    }
    if (!isNil(total)) {
      return currency(total).format();
    }
    return '-';
  };

  const { freightChargeTypeSelectTestId } = getStopFreightChargeTestIds({
    stopIdx:
      context.shipmentType === ShipmentType.Regular ? context.stopIdx : -1,
  });

  return (
    <TableRow data-testid="freight-charge-row">
      <TableCell>
        <FormControl sx={MT_IF_HAS_DESCRIPTION_STYLES}>
          <InputLabel>Freight</InputLabel>
          <Select
            data-testid={freightChargeTypeSelectTestId}
            disabled={
              disabledIfFinalizedOrLater ||
              ((status === OrderStatus.Finalized ||
                status === OrderStatus.Invoiced) &&
                hideFromBilling === true)
            }
            onChange={(e) => {
              const parsedValue = e.target.value as FreightBillingMethod;
              if (e.target.value !== HIDE_FROM_BILLING) {
                setValue(
                  `${namePrefix}.freightCharge.billingMethod`,
                  parsedValue,
                );
                setHideFromBilling?.(false);
                if (
                  !VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS.includes(
                    parsedValue,
                  )
                ) {
                  setValue(`${namePrefix}.freightCharge.discountRate`, null);
                }
              } else {
                setValue(
                  `${namePrefix}.freightCharge.billingMethod`,
                  FreightBillingMethod.FlatRate,
                );
                setHideFromBilling?.(true);
                setValue(`${namePrefix}.freightCharge.rate`, 0);
                setValue(`${namePrefix}.freightCharge.quantity`, 0);
                setValue(`${namePrefix}.freightCharge.discountRate`, null);
                setValue(`${namePrefix}.freightCharge.totalCharge`, 0);
              }
            }}
            size="small"
            value={
              hideFromBilling === true
                ? HIDE_FROM_BILLING
                : (billingMethod ?? '')
            }
            label="Freight"
          >
            {Object.values(FreightBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
            {context.shipmentType === ShipmentType.Regular && (
              // Order Charges shipments don't have the 'No Charge' option because its not possible to hide them from billing
              // (If they're meant to be hidden, the order charges shipment shouldn't exist in the first place)
              <MenuItem
                key={HIDE_FROM_BILLING}
                value={HIDE_FROM_BILLING}
                disabled={
                  (status === OrderStatus.Finalized ||
                    status === OrderStatus.Invoiced) &&
                  hideFromBilling !== true
                }
              >
                Do not bill
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {!hideFromBilling && (
          <DescriptionComponent
            keyString={`${namePrefix}.freightCharge`}
            disabled={disabledIfInvoicePosted}
          />
        )}
      </TableCell>
      <TableCell>
        {!hideFromBilling && (
          <RateField
            disabled={disabledIfFinalizedOrLater}
            context={context}
            billingMethod={billingMethod}
            tariff={tariff}
            loading={freightChargeLoading}
          />
        )}
        {tariff?.tariffZone.type === TariffZoneType.Miles &&
          billingMethod === FreightBillingMethod.Tariff &&
          !freightChargeLoading && (
            <MilesComponent
              context={context}
              disabled={disabledIfFinalizedOrLater || inBillingReview}
              shouldSetFreightChargeQuantity={false}
            />
          )}
        {isEditMode &&
          isOrderPageRating === false &&
          !isNil(savedTariff) &&
          !isNil(savedTariffUuid.current) &&
          savedTariffUuid.current !== tariffUuid &&
          !freightChargeLoading && (
            <Typography
              data-test-id={
                FREIGHT_CHARGE_NEW_TARIFF_AUTO_APPLIED_MESSAGE_TEST_ID
              }
              sx={{ color: theme.palette.primary.main, fontSize: '14px' }}
            >
              {buildNewTariffAppliedMessage({
                originalTariff: formatTariffRateString({
                  tariff: savedTariff.current,
                  rate: savedTariffRate.current,
                  total: savedTariffTotal.current,
                }),
              })}
            </Typography>
          )}
      </TableCell>
      <TableCell>
        {!hideFromBilling && (
          <QuantityField
            disabled={disabledIfFinalizedOrLater}
            billingMethod={billingMethod}
            tariff={tariff}
            context={context}
          />
        )}
      </TableCell>
      <TableCell colSpan={2}>
        <Typography
          sx={MT_IF_HAS_AUTHO_CODE_STYLES}
          data-testid={FREIGHT_CHARGE_TOTAL_TEST_ID}
        >
          {formatFreightChargeTotal()}
        </Typography>
        {!isEmpty(errorMessage) && (
          <Typography
            data-testid="freight-charge-error-message"
            sx={{ color: 'red', fontSize: '14px' }}
          >
            {errorMessage}
          </Typography>
        )}
        {!isNil(discountRate) && (
          <Typography sx={{ fontSize: '16px', color: theme.palette.grey[500] }}>
            {`Discount Rate: ${discountRate}`}
          </Typography>
        )}
        <AuthoCodeComponent
          authoCode={authoCode ?? ''}
          keyString={`${namePrefix}.freightCharge`}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
    </TableRow>
  );
};

export default memo(FreightChargeRow);

import { FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import { isEmpty, isNil, isString } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  PersonsQuery,
  PersonStatus,
  usePersonsQuery,
  VehicleTypeStatus,
  useVehicleTypesMinimalQuery,
} from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { AutocompletePerson } from '../../standard/components/autocomplete-person';
import { useOrderFormEditAccess } from '../contexts/order-form-edit-access-context';
import { StopType } from '../forms/stop-type';
import { OrderFormValues } from '../forms/types';
import { addressIsEmpty } from '../forms/utils';
import { useOrderFormContact } from '../hooks/use-order-form-contact';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from './constants';

type PersonWithFullName = PersonsQuery['persons']['persons'][number] & {
  fullName: string;
};

type OrderPersonProps = {
  editingDisabled: boolean;
  isEditMode: boolean;
  setAutocompletePerson: (person: AutocompletePerson | null) => void;
};

const getPersonFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  let fullName = firstName;
  if (lastName !== '') {
    if (fullName !== '') {
      fullName += ' ';
    }
    fullName += lastName;
  }
  return fullName;
};

const OrderPerson: FunctionComponent<OrderPersonProps> = ({
  editingDisabled,
  isEditMode,
  setAutocompletePerson,
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const { contact } = useOrderFormContact();
  const contactStations = contact?.contactStations;
  const contactUuid = useWatch({ control, name: 'contactUuid' });
  const { data: personData, loading: loadingPersons } = usePersonsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { contactUuid },
  });
  const { data: vehicleTypesData } = useVehicleTypesMinimalQuery({
    // We have other active queries refetching vehicle types, so it's totally safe to rely on the cache. In any case,
    // this data is used only for auto-filling the form and if a vehicle type is missing it's no big deal and the user
    // can select it manually.
    fetchPolicy: 'cache-first',
  });

  const personOptions = useMemo<PersonWithFullName[]>(
    () =>
      personData?.persons.persons
        .filter(({ status }) => status === PersonStatus.Active)
        .map((person) => {
          const fullName = getPersonFullName({
            firstName: person.firstName,
            lastName: person.lastName,
          });
          return {
            ...person,
            fullName,
          };
        }) ?? [],
    [personData],
  );

  const personName = useWatch({ control, name: 'personName' });
  const serviceUuid = useWatch({ control, name: 'serviceUuid' });
  const billingPartyContactStationId = useWatch({
    control,
    name: 'contactStationId',
  });
  const vehicleTypeUuid = useWatch({ control, name: 'vehicleTypeUuid' });
  // Note that there is no address autocomplete for transferAddress.
  const inboundAddress = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.address`,
  });
  const outboundAddress = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.address`,
  });
  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });
  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });
  const inboundSpecialInstructions = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.specialInstructions`,
  });
  const outboundSpecialInstructions = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.specialInstructions`,
  });

  const { disabledIfInvoicePosted } = useOrderFormEditAccess();

  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  useEffect(() => {
    if (!ffUseStations) {
      return;
    }

    if (!isNil(contactStations)) {
      const defaultStationPerson = contactStations.find(
        (cs) => cs.id === billingPartyContactStationId,
      )?.defaultPerson;

      if (!isEditMode) {
        const fullName = getPersonFullName({
          firstName: defaultStationPerson?.firstName ?? '',
          lastName: defaultStationPerson?.lastName ?? '',
        });

        setValue('personName', fullName);
        setValue('personPhoneNumber', defaultStationPerson?.phone ?? '');
        setValue('personEmail', defaultStationPerson?.email ?? '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingPartyContactStationId, contactStations, ffUseStations]);

  return (
    <Stack direction="row" gap="14px">
      <FormControl
        sx={{
          flexBasis: '200px',
          minWidth: '180px',
        }}
      >
        <AutocompleteFuzzy<PersonWithFullName, false, true, true>
          options={personOptions}
          matchSortOptions={{
            keys: ['fullName', 'firstName', 'lastName', 'email'],
          }}
          disabled={
            editingDisabled || disabledIfInvoicePosted || loadingPersons
          }
          getOptionLabel={(option) =>
            isString(option) ? option : option.fullName
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Contact (caller)"
              size="small"
              onChange={(event) => {
                setValue('personName', event.target.value);
              }}
            />
          )}
          value={personName ?? ''}
          onChange={(_, personOrName) => {
            if (isString(personOrName)) {
              setValue('personName', personOrName);
            } else if (isNil(personOrName)) {
              setValue('personName', '');
            } else {
              // Phone number and name should be updated if a different person is selected, but default service and
              // vehicle type are changed only if they are not already set.
              setValue('personName', personOrName.fullName);
              setValue('personPhoneNumber', personOrName.phone ?? '');
              setValue('personEmail', personOrName.email ?? '');

              const {
                defaultService,
                defaultVehicleType,
                defaultPickupAddress,
                defaultDeliveryAddress,
              } = personOrName;
              if (!isNil(defaultService) && isEmpty(serviceUuid)) {
                // There's a hook in the StopDetails component that will handle updates to the service and the stops.
                // It uses the autocompletePerson field. The reason we don't set addresses in this case is that having
                // a non-empty address for a stop will prevent the stop's type from being updated based on the service.
                setAutocompletePerson(personOrName);
                setValue('serviceUuid', defaultService.uuid);
              } else {
                if (
                  addressIsEmpty(inboundAddress) &&
                  !isNil(defaultPickupAddress) &&
                  inboundStopType === StopType.Pickup
                ) {
                  setValue(
                    `stops.${INBOUND_STOP_IDX}.address`,
                    defaultPickupAddress,
                  );
                  if (isEmpty(inboundSpecialInstructions)) {
                    setValue(
                      `stops.${INBOUND_STOP_IDX}.specialInstructions`,
                      defaultPickupAddress.specialInstructions,
                    );
                  }
                }
                if (
                  addressIsEmpty(outboundAddress) &&
                  !isNil(defaultDeliveryAddress) &&
                  outboundStopType === StopType.Delivery
                ) {
                  setValue(
                    `stops.${OUTBOUND_STOP_IDX}.address`,
                    defaultDeliveryAddress,
                  );
                  if (isEmpty(outboundSpecialInstructions)) {
                    setValue(
                      `stops.${OUTBOUND_STOP_IDX}.specialInstructions`,
                      defaultDeliveryAddress.specialInstructions,
                    );
                  }
                }
              }
              if (
                !isNil(defaultVehicleType) &&
                isEmpty(vehicleTypeUuid) &&
                !isNil(vehicleTypesData) &&
                vehicleTypesData.vehicleTypes.some(
                  (vt) =>
                    vt.uuid === defaultVehicleType.uuid &&
                    vt.status === VehicleTypeStatus.Active,
                )
              ) {
                setValue('vehicleTypeUuid', defaultVehicleType.uuid);
              }
            }
          }}
          autoHighlight
          // This field can be cleared, but we don't need the clear button because the space for
          // the field is compact.
          disableClearable
          freeSolo
        />
      </FormControl>
      <Controller
        name="personPhoneNumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl
            sx={{
              flexBasis: '155px',
              minWidth: '150px',
            }}
          >
            <TextField
              label="Contact Phone"
              sx={{ width: '100%' }}
              error={!isNil(error)}
              disabled={editingDisabled || disabledIfInvoicePosted}
              size="small"
              value={value ?? ''}
              onChange={onChange}
            />
            {!isNil(error) && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                {error.message?.toString()}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Controller
        name="personEmail"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl
            sx={{
              width: '100%',
              flexBasis: '220px',
              minWidth: '180px',
            }}
          >
            <TextField
              label="Contact Email"
              sx={{ width: '100%' }}
              error={!isNil(error)}
              disabled={editingDisabled || disabledIfInvoicePosted}
              size="small"
              value={value ?? ''}
              onChange={onChange}
            />
            {!isNil(error) && (
              <FormHelperText sx={{ color: '#D32F2F' }}>
                {error.message?.toString()}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Stack>
  );
};

export default React.memo(OrderPerson);

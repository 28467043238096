import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  CurrentOrderStatusFragment,
  LegDocument,
  StandardOrderFragmentFragment,
  StandardOrderQueryResult,
  useCreateOrderSnapshotMutation,
  useCreateStandardOrderMutation,
  useCurrentOrderStatusLazyQuery,
  useUpdateStandardOrderMutation,
  useStandardOrderLazyQuery,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { OrderFormValues } from '../forms/types';
import {
  DUPLICATE_EVERYTHING,
  DUPLICATE_NOTHING,
  saveOrder,
  updateOrder,
} from '../forms/utils';
import { OnSubmitParams } from '../types';
import { useLoadOrderForm } from './use-load-order-form';

const useSaveOrderForm = ({
  isEditMode,
  completeRebillAfterSave,
  modalOnClose,
}: {
  isEditMode: boolean;
  completeRebillAfterSave:
    | (({ newContactUuid }: { newContactUuid: string }) => void)
    | undefined;
  modalOnClose: (() => void) | undefined;
}) => {
  const { useAllCaps } = useMe();
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const form = useFormContext<OrderFormValues>();
  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );
  const ffUseNewShipmentCreateInputs = useFeatureFlag(
    FeatureFlag.FF_USE_NEW_SHIPMENT_CREATE_INPUTS,
  );
  const { fetchData, initialOrder } = useLoadOrderForm();
  const [createOrderSnapshot] = useCreateOrderSnapshotMutation();
  const [createOrderMutation] = useCreateStandardOrderMutation();
  const [updateOrderMutation] = useUpdateStandardOrderMutation({
    refetchQueries: [LegDocument],
  });
  const [getStandardOrder] = useStandardOrderLazyQuery();

  const [refetchCurrentOrderStatus] = useCurrentOrderStatusLazyQuery();

  const leavePage = ({
    orderUuid,
    isDocScan,
    noRedirect,
    duplicateEverything,
    duplicateNothing,
    saveAndPrint,
  }: {
    orderUuid: string;
  } & OnSubmitParams) => {
    /**
     * Reload the page to ensure the form data is properly replaced.
     * The current form logic is quite convoluted and reloading the page is a safer
     * alternative than changing the core form construction logic.
     * // TODO: Refactor the initializeForm() hook to be used in the promise callback instead of router.reload().
     */
    if (duplicateEverything === true) {
      window.location.href = `/orders?orderUuid=${orderUuid}&duplicate=${DUPLICATE_EVERYTHING}`;
      return;
    }
    if (duplicateNothing === true) {
      window.location.href = `/orders?orderUuid=${orderUuid}&duplicate=${DUPLICATE_NOTHING}`;
      return;
    }
    if (!isNil(modalOnClose) && noRedirect === false) {
      modalOnClose();
      return;
    }
    if (isDocScan === true) {
      return;
    }
    if (noRedirect === true) {
      return;
    }
    if (saveAndPrint === true) {
      navigate(`/orders?orderUuid=${orderUuid}`, { replace: true });
      return;
    }

    if (pathname.includes('/order-entry')) {
      navigate('/orders');
    } else if (pathname.includes('/recurring-orders')) {
      navigate(-1);
    } else {
      setSearchParams((sp) => {
        const newParams = new URLSearchParams(sp);
        newParams.delete('orderUuid');
        newParams.delete('duplicate');
        return newParams;
      });
    }
    modalOnClose?.();
    setOpenedOrderUuid(undefined);
  };

  const saveSnapshotAndFetchData = async (
    uuid: string | undefined,
    orderBeforeUpdate: StandardOrderFragmentFragment | undefined,
  ): Promise<StandardOrderQueryResult | null | undefined> => {
    if (isNil(uuid)) {
      console.error('Unable to save snapshot - nil uuid');
      return uuid;
    }
    const updatedOrderResponse = await getStandardOrder({
      variables: { uuid },
    });
    const updatedOrder = updatedOrderResponse.data?.standardOrder;
    if (!isNil(updatedOrder)) {
      // We always fetch the order to generate the snapshot, so we might as
      // well re-hydrate the form always
      await fetchData({
        uuid,
        standardOrderFragment: updatedOrder,
      });
      createOrderSnapshot({
        variables: {
          orderEventSnapshotCreateInput: {
            orderUuid: updatedOrder.uuid,
            previousSnapshot: !isNil(orderBeforeUpdate)
              ? JSON.stringify(orderBeforeUpdate)
              : undefined,
            snapshot: JSON.stringify(updatedOrder),
          },
        },
      });
    }
    return updatedOrderResponse;
  };

  /*
   * Save the order form data to the backend.
   *
   * @throws {Error} If there is an error while saving or updating the order.
   */
  const saveOrderForm = async (args: OnSubmitParams) => {
    const orderValues = form.getValues();
    const stopValues = orderValues.stops ?? [];
    const packageValues = orderValues.packages ?? [];

    if (!isEditMode) {
      await saveOrder({
        orderValues,
        stopValues,
        packageValues,
        createOrderMutation,
        fetchOrder: fetchData,
        refetchOrderAfterSave: args.refetchOrderAfterSave,
        useNewShipmentCreateInputs: ffUseNewShipmentCreateInputs,
        useAllCaps,
      });
    } else {
      const { errorMessage } = await updateOrder({
        orderValues,
        updateOrderMutation,
        saveSnapshot: (uuid: string | undefined) => {
          return saveSnapshotAndFetchData(uuid, initialOrder);
        },
        useAllCaps,
        additionalUpdateFns: args.additionalUpdateFns,
      });
      if (!isNil(errorMessage)) {
        throw new Error(errorMessage);
      }
      if (!isNil(completeRebillAfterSave)) {
        completeRebillAfterSave({
          newContactUuid: orderValues.contactUuid,
        });
      }
    }
    leavePage({
      orderUuid: orderValues.uuid,
      ...args,
    });
  };

  const refetchOrderStatus = async (
    uuid: string,
  ): Promise<CurrentOrderStatusFragment | null> => {
    const response = await refetchCurrentOrderStatus({
      variables: {
        uuid,
      },
    });

    if (response.error) {
      return null;
    }
    return response.data?.order ?? null;
  };

  return {
    leavePage,
    saveOrderForm,
    refetchOrderStatus,
  };
};

export { useSaveOrderForm };
